import React, {useEffect, useState} from "react";

let audio = false;
const StudioMusic = ({music}) => {

    const [musicFile, setMusicFile] = useState(music);

    useEffect(()=>{
        if(music.length > 0){

            setMusicFile(music);
            if(audio!== false){
                audio.pause();
                audio.src = music[0].url
            }

            audio = document.getElementById('selectedMusic');
            try {
                audio.play().then(r => console.log('playing')).catch(err=> console.log(err));
                audio.volume = music[0].volume;
                audio.loop = music[0].loop;

                audio.onended = function (){
                    console.log('stop');
                }
            }catch (e) {
                console.log('getting error while playing audio', e)
            }

        }else{
            if(audio!== false){
                audio.pause();
            }

        }

        return () => {
            if(audio!== false){
                audio.pause();
                console.log('pause');
            }
        }

    },[music])

    return(
        <>
            {
                music.length > 0 ?
                    <audio id="selectedMusic" style={{display: 'none'}}>
                        <source src={music[0].url} type="audio/mpeg" />
                    </audio>
                : ''
            }
        </>
    )
}

export default React.memo(StudioMusic);