import React, {useEffect, useRef} from 'react';

const OverlayCanvas = ({customization, setCustomization}) => {
    const overlayRef = useRef(null);
    const overlayCanvasRef  = useRef(null);
    const overlay  = customization.overlay;
    useEffect(() => {
        if(overlay.enable === true){
            setTimeout(() => {
                const canvas = overlayCanvasRef.current;
                const context = canvas.getContext("2d");
                context.drawImage(overlayRef.current, 0, 0,1280,720);
                const overlayCanvasStream = canvas.captureStream(30);
                const newOverlay = {...overlay,stream:overlayCanvasStream}
                setCustomization({...customization, overlay: newOverlay});
            },3000)
        }
    }, [overlay.enable,overlay.src]);

    return (
        <>
            <div className='overlayCanvas' style={{display:'none'}}>
                <canvas ref={overlayCanvasRef} width="1280" height="720"/>
            </div>
            {overlay.enable === true ? <img ref={overlayRef} crossOrigin="anonymous" src={overlay.src} className="img-left-absolute" /> : null}
        </>
    );
};

export default OverlayCanvas;
