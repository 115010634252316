import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TrainingTitles from "./TrainingTitles";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const TrainingArticles = ({ location }) => {

    const brandName = useSelector(state => state.rebrand.data);

    const id = queryString.parse(location.search).id;
    const art_id = queryString.parse(location.search).art_id;

    const articleData = useSelector(state => state.support);
    const articleData2 = useSelector(state => state.support.articles);

    const filterArticle = articleData2.filter(article => article.id === id)[0];
    const filterName = filterArticle.items.filter((id) => id.id == art_id)[0];

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'StreamReel'} | Help & Support </title>
            </Helmet>
            <Navbar />

            <section className="traning-sec" style={{ padding: "55px 0px 0 0px" }}>
                <div className="container">
                    <div className="row">

                        <TrainingTitles location={location} id={id} supportData={articleData} name={filterName} />

                        <div className="col-md-8">
                            <div className="popular-topics-content">

                                <div className="tab-content mt-4" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-client" role="tabpanel" aria-labelledby="v-pills-client-tab">

                                        {
                                           
                                                filterArticle.items.length > 0 ?
                                                    filterArticle.items.map((item, index) => {
                                                        return (
                                                            item.id == art_id ?
                                                                <>
                                                                    <div className="text-white"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: item.content
                                                                        }}>
                                                                    </div>
                                                                </>
                                                                : ''
                                                        )
                                                    })
                                                    : ''
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default TrainingArticles;