import React from "react";
import SocialNetwork from "./SocialNetwork";

import facebookIcon from "../../images/logo-facebook.svg";
import youtubeIcon from "../../images/logo-youtube-grey.svg";
import twitchIcon from "../../images/TWITCH-Default.png";
import streamIcon from "../../images/STREAMREEL-Default.png";

const IntegrationHead = ({data} ) => {
    return(
        <>
            <section className="integration-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="integration-head text-white text-center">
                                <h1>Integrations</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12 mx-auto">
                            <div className="integration-box">
                                <ul className="list-inline d-flex  flex-wrap justify-content-center">
                                    <SocialNetwork data={data.facebook.length} icon={facebookIcon} name="Facebook" />
                                    <SocialNetwork data={data.youtube.length} icon={youtubeIcon} name="Youtube" />
                                    <SocialNetwork data={data.twitch.length} icon={twitchIcon} name="Twitch" />
                                    <SocialNetwork data={data.customRtmp.length} icon={streamIcon} name="LinkedIn & RTMP " />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IntegrationHead;