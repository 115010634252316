import React, {useEffect, useRef} from 'react';
let merger = false;
const VideoPreview = ({customization, setCustomization}) => {
    const videoRef = useRef(null);
    const video  = customization.video;

    useEffect(() => {
        if(video.enable === true){
            videoRef.current.src = video.src;
            const newVideo = {...video,stream:videoRef.current}
            setCustomization({...customization, video: newVideo});
        }
        else{
            videoRef.current.src = '';
            const newVideo = {...video,stream:false,src:'',enable:false}
            setCustomization({...customization, video: newVideo});
        }
    }, [video.enable,video.src]);

    return (
        <div>
            <video ref={videoRef} autoPlay playsInline muted/>
        </div>
    );
};

export default VideoPreview;
