import React, {useEffect, useRef} from 'react';

const LogoCanvas = ({customization, setCustomization}) => {
    const logoRef = useRef(null);
    const logoCanvasRef  = useRef(null);
    const logo  = customization.logo;
    useEffect(() => {
        if(logo.enable === true){
            setTimeout(() => {
                const canvas = logoCanvasRef.current;
                const context = canvas.getContext("2d");
                context.drawImage(logoRef.current, 0, 0, 200, 100);
                const logoCanvasStream = canvas.captureStream();
                const newLogo = {...logo,stream:logoCanvasStream}
                setCustomization({...customization, logo: newLogo});
            },3000)
        }
    }, [logo.enable,logo.src]);



    return (
        <>
            <div className='logoCanvas' style={{display:'none'}}>
                <canvas ref={logoCanvasRef} />
            </div>
            {logo.enable === true ? <img crossOrigin="anonymous"  ref={logoRef} className="img-right-absolute" src={logo.src} style={{width:100}}/> : null}
        </>
    );
};

export default LogoCanvas;
