import React, { useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import DashboardHead from "./DashboardHead";
import DashboardContent from "./DashboardContent";
import DashboardAbout from "./DashboardAbout";
import { Helmet } from "react-helmet";
import Webinar from "./Webinar";
import { useSelector, useDispatch } from "react-redux";
import { loadUser } from "../../actions/authAction";

const Dashboard = () => {

    const brandName = useSelector(state => state.rebrand.data);
    const dispatch = useDispatch()

    
    useEffect(() => {
        dispatch(loadUser())
    }, [])

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'StreamReel'} | Dashboard </title>
            </Helmet>

            <Navbar />
            <DashboardHead />
            <DashboardContent />
            <DashboardAbout />
            <Webinar />
            <Footer />
        </>
    )
}

export default Dashboard;