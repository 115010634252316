import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AddAccount from "./AddAccount";
import {useSelector} from "react-redux";

const AccountManagement = () => {
    const brandName = useSelector(state => state.rebrand.data);
    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'StreamReel'} | Account Management </title>
            </Helmet>
            <Navbar/>

            <section className="add-account-sec">
                <div className="container">
                    <AddAccount/>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default AccountManagement;