import React, {useEffect, useRef, useState} from 'react';
import Footer from "../Footer";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophone, faMicrophoneSlash, faVideoSlash, faVideo } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap';
import {useDispatch} from "react-redux";
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons";
import profileImage from "../../images/profile.jpg";
import camImage from "../../images/studio_cam.gif";
let localStream = false;
const GuestCamMicSettings = ({setHostStream, setOpenStudioDetails}) => {
    const dispatch = useDispatch();


    const [buttonText, setButtonText] = useState('Enter Studio');

    const [hostName, setHostName] = useState('')
    const videoRef = useRef(null);
    const profileRef = useRef(null);


    const [localUser, setLocalUser] = useState({
        video:true,
        audio:true
    })

    // stream.getAudioTracks()[0].enabled = false
    // stream.getVideoTracks()[0].disabled = false
    const handleSubmit = (e) => {
        e.preventDefault();
        setOpenStudioDetails(true);
        //dispatch(updateStudioData('host_name', hostName))
        setHostStream({
            stream:localStream,
            name:hostName
        });
        dispatch({type: 'UPDATE_GUEST_NAME', payload: hostName})

        // update Guest Redux
    }
    useEffect(() => {
        // Fetch Camera Feed and display in webpage
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then(stream => {
                videoRef.current.srcObject = stream;
                setLocalUser({video:true,audio: true});
                localStream = stream;
            })
            .catch(err => {
                // handle if user rejects the permission
                setLocalUser({video:false,audio: false});
                setButtonText('Enter without Camera');
                console.error("error:", err);
            });

    }, []);

    const toggleAudioVideo = (type,status) => {
        if(type === 'audio'){
            localStream.getAudioTracks()[0].enabled = status
            dispatch({type: 'UPDATE_GUEST_AUDIO_STATUS', payload: status})
        }
        if(type === 'video'){
            localStream.getVideoTracks()[0].enabled = status

            dispatch({type: 'UPDATE_GUEST_VIDEO_STATUS', payload: status})
        }
        setLocalUser({
            ...localUser,
            [type]: status
        })
    }

    useEffect(() => {
        if(localUser.video && videoRef !== null && localStream !== false){
            videoRef.current.srcObject = localStream;
        }
    },[localUser.video])

    return (
        <>
            <div className='broadSetting'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>

                            <div className='camGif'>
                                <img src={camImage} />
                            </div>

                            <div className="broadcast-content">
                                <div className='camCheck'>
                                    <h2 className='text-center'>Let's Check Your Camera and Mic <span className="icon skyblue" title="Browser will prompt you for webcam and mic permission. Please grant the permission to proceed further">
                                <FontAwesomeIcon icon={faQuestionCircle}> </FontAwesomeIcon>
                            </span></h2>
                                    {
                                        localUser.audio ? '' :  <div className='camNotification'>
                                            Couldn't find a microphone. It is possible you don't have one plugged in.
                                        </div>
                                    }
                                    <div className='micCheck'>
                                        <div className='micCheck-left'>

                                            {localUser.video === false ? <img src={profileImage} ref={profileRef}/> : <video ref={videoRef} autoPlay playsInline muted/>}

                                        </div>
                                    </div>
                                    <div className='micControll'>
                                        <ul>
                                            {localUser.audio === true ? <li onClick={() => toggleAudioVideo('audio',false)}>
                                                <span className='camIcon'><FontAwesomeIcon icon={faMicrophone}> </FontAwesomeIcon></span>
                                                <span>Mute</span>
                                            </li> : <li className='active' onClick={() => toggleAudioVideo('audio',true)}>
                                                <span className='camIcon'><FontAwesomeIcon icon={faMicrophoneSlash}> </FontAwesomeIcon></span>
                                                <span>Unmute</span>
                                            </li> }

                                            {localUser.video === true ? <li onClick={() => toggleAudioVideo('video',false)}>
                                                <span className='camIcon'><FontAwesomeIcon icon={faVideo}> </FontAwesomeIcon></span>
                                                <span>Stop Cam</span>
                                            </li> : <li className='active' onClick={() => toggleAudioVideo('video',true)}>
                                                <span className='camIcon'><FontAwesomeIcon icon={faVideoSlash}> </FontAwesomeIcon></span>
                                                <span>Start Cam</span>
                                            </li>}

                                        </ul>
                                    </div>
                                </div>

                                <div className='pt-5 dispName'>
                                    <form onSubmit={handleSubmit} >
                                        <div className="form-group">
                                            <label>Display Name</label>
                                            <input type="text" required className="form-control" id="inputTitle" placeholder="Add Title" value={hostName} onChange={(e) => setHostName(e.target.value)}/>
                                        </div>
                                        <Button variant="blue w-100 p-3 btOrg" type="submit">{buttonText}</Button>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer/>

        </>
    );
};

export default GuestCamMicSettings;
