import React, { useEffect, useState } from 'react';
import user1 from "../../../images/user-women.jpg";
import checkIcon from "../../../images/check.png";
import cancelIcon from "../../../images/cancel.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import fbLogo from "../../../images/logo-facebook.svg";
import ytLogo from "../../../images/youtube-round.png";
import fbLogo2 from "../../../images/facebook-round.png";
import rtmpIcon from "../../../images/rmtp-icon.png";
import twitchIcon from "../../../images/TWITCH-Default.png";
import Calendar from "react-calendar";
import axios from "axios";
import { baseURL } from "../../../global/global";
import swal from "sweetalert";
import { updateStudioData } from "../../../actions/studioAction";
import { Link } from "react-router-dom";
import { setAlert } from "../../../actions/alert";
import Alert from "../../Alert";


const allowedExt = ['image/png', 'image/jpg', 'image/jpeg', 'image/JPEG'];

const Broadcast = () => {

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const socialData = useSelector(state => state.social);
    const studioData = useSelector(state => state.studio);

    const [loader, setLoader] = useState(false);
    const [socialId, setSocialId] = useState([]);
    const [disableId, setDisableId] = useState([]);
    const [facebook, setFacebook] = useState([]);
    const [rtmp, setRtmp] = useState([]);
    const [youtube, setYoutube] = useState([]);
    const [twitch, setTwitch] = useState([]);
    const [hours, setHours] = useState('12');
    const [minutes, setMinutes] = useState('05');
    const [thumbnail, setThumbnail] = useState('');
    const [thumbStatus, setThumbStatus] = useState(false);
    const [broadCast, setBroadCast] = useState({
        title: '',
        description: '',
        social_platform: [],
        schedule: {
            schedule: true,
            date: '',
            hours: '',
            minutes: '',
            clock: 'AM',
            raw_date: ''
        },
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
    const [dateState, setDateState] = useState(new Date());

    const handleChangeDate = (e) => {
        setDateState(e);

        let tempBroad = {
            ...broadCast, schedule: {
                ...broadCast.schedule, date: e.toLocaleString().split(',')[0], raw_date: e.toString()
            }
        };
        setBroadCast(tempBroad)

        dispatch(updateStudioData('schedule', tempBroad.schedule))


    }

    const handleInputChange = (e) => {
        setBroadCast({ ...broadCast, [e.target.name]: e.target.value })
    }

    const handleInputSocial = (type, social_id, rtmp) => {

        if (studioData.live.enable)
            return

        if (socialId.includes(social_id)) {

            let tempArr = broadCast.social_platform.map(item => (item.social_id === social_id ? { ...item, selected: false } : { ...item, selected: item.selected }));
            setBroadCast({ ...broadCast, social_platform: tempArr });
            setSocialId(socialId.filter(item => item !== social_id))
            setDisableId([...disableId, social_id]);

            dispatch(updateStudioData('social_platform', tempArr))

        } else if (disableId.includes(social_id)) {

            let tempArr = broadCast.social_platform.map(item => (item.social_id === social_id ? { ...item, selected: true } : { ...item, selected: item.selected }));
            setBroadCast({ ...broadCast, social_platform: tempArr });
            setSocialId([...socialId, social_id])


            dispatch(updateStudioData('social_platform', tempArr))
        }
        else {
            let socialObj = { type, rtmp, social_id, selected: true }

            setSocialId([...socialId, social_id]);
            setBroadCast({ ...broadCast, social_platform: [...broadCast.social_platform, socialObj] });

            let tempObj = { ...broadCast, social_platform: [...broadCast.social_platform, socialObj] };
            dispatch(updateStudioData('social_platform', tempObj.social_platform))
        }
    }

    const handleScheduleChange = (e) => {
        let tempSchedule = { ...broadCast, schedule: { ...broadCast.schedule, [e.target.name]: e.target.value } };

        setBroadCast(tempSchedule)

        dispatch(updateStudioData('schedule', tempSchedule.schedule))
    }

    const handleRemoveThumbnail = (status) => {
        setThumbnail('');
        setThumbStatus(status)

        if (status === false)
            dispatch(updateStudioData('thumbnail', ''))
    }

    const handleFileChange = (e) => {

        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {

                let allowedSize = 100000000;

                if (e.target.files[0].size < allowedSize) {
                    setLoader(true);

                    const formData = new FormData();
                    formData.append('broadcast_id', studioData.id);
                    formData.append('file', e.target.files[0])

                    const options = {
                        headers: {
                            'Authorization': auth.token
                        }
                    }

                    axios.post(`${baseURL}upload-broadcast-thumbnail`, formData, options).then(res => {
                        if (res.data.status === true) {
                            setThumbnail([res.data.path]);
                            dispatch(updateStudioData('thumbnail', res.data.path))
                        } else {
                            dispatch(setAlert(res.data.message, 'danger'))
                        }
                        setLoader(false);

                    })

                } else {
                    swal("Oops!", "Max allowed size for video 100MB");
                }

            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }



    useEffect(() => {
        /* initial social data */
        setYoutube(socialData.youtube);
        setFacebook(socialData.facebook);
        setRtmp(socialData.customRtmp);
        setTwitch(socialData.twitch);

        /* initial broadcast data */
        setBroadCast({
            ...studioData,
            title: studioData.title,
            description: studioData.description,
            social_platform: studioData.social_platform,
            schedule: studioData.schedule
        })

        setHours("Date", studioData.schedule?.hours);
        setMinutes("Date2", studioData?.schedule?.minutes);

        /* initial selected social */
        if (studioData.social_platform.length > 0) {
            setSocialId(
                studioData.social_platform.map(item => (
                    item.social_id
                ))
            )
        }
            setDateState(new Date(studioData.schedule.raw_date));

    }, [studioData])

    useEffect(() => {
        setThumbnail(studioData.thumbnail);
    }, [])
    return (
        <Tab.Pane eventKey="first">
            <Alert />
            <h2 className="tabTitle alt">Broadcast</h2>
            <div className="broadcast-content">
                <form action="" method="POST">
                    <div className="content-box content-box--broadcast box-form-control purple-box text-white p-3 mb-4">
                        <div className="row">
                            <div className="col-12">
                                <h6>Broadcast To
                                    <span className="icon skyblue" title="Select the social accounts in which you would live to broadcast ">
                                        <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                                    </span>
                                </h6>
                            </div>
                            <div className="col-12">
                                <div>
                                    {
                                        facebook.length === 0 && youtube.length === 0 && rtmp.length === 0 ?
                                            <div className="alert alert-info">
                                                It seems you do not have any Social Account connected. <br /> Please connect one by <Link style={{ color: '#05a1eb' }} to="/integration">click here</Link>
                                            </div>
                                            : ''
                                    }
                                    <ul className="broadcast-content-box">
                                        {
                                            facebook.length > 0 ?
                                                facebook.map((item, index) => (
                                                    <li key={index}>
                                                        <div className={`broadcast-content-box-circle circle-img-wrap ${socialId.includes(item.id) ? 'active' : ''}`}>
                                                            <div className="circle-img">
                                                                <img src={item.image} alt="user img" />
                                                                <div className='socialIcon'>
                                                                    <img src={fbLogo2} />
                                                                </div>
                                                            </div>
                                                            <div style={{ visibility: 'visible' }} className="circle-img-select" onClick={() => handleInputSocial('facebook', item.id, '')}>
                                                                {
                                                                    <img src={checkIcon} alt="user img" />
                                                                }
                                                            </div>

                                                        </div>
                                                        <span className='app-name'>{item.username}</span>
                                                    </li>
                                                ))
                                                : ''
                                        }
                                        {
                                            youtube.length > 0 ?
                                                youtube.map((item, index) => (
                                                    <li key={index} title={item.username}>
                                                        <div className={`broadcast-content-box-circle circle-img-wrap ${socialId.includes(item.id) ? 'active' : ''}`}>
                                                            <div className="circle-img">
                                                                <img src={item.image} alt="user img" />
                                                                <div className='socialIcon'>
                                                                    <img src={ytLogo} />
                                                                </div>
                                                            </div>

                                                            <div style={{ visibility: 'visible' }} className="circle-img-select" onClick={() => handleInputSocial('youtube', item.id, '')} >
                                                                {
                                                                    <img src={checkIcon} alt="user img" />
                                                                }
                                                            </div>
                                                        </div>
                                                        <span className='app-name'>{item.username}</span>
                                                    </li>
                                                ))
                                                : ''
                                        }
                                        {
                                            rtmp.length > 0 ?
                                                rtmp.map((item, index) => (
                                                    <li key={index} title={item.username}>
                                                        <div className={`broadcast-content-box-circle circle-img-wrap ${socialId.includes(item.id) ? 'active' : ''}`}>
                                                            <div className="circle-img">
                                                                <img src={item.image} alt="user img" />
                                                                <div className='socialIcon'>
                                                                    <img src={rtmpIcon} />
                                                                </div>
                                                            </div>

                                                            <div style={{ visibility: 'visible' }} className="circle-img-select" onClick={() => handleInputSocial('rtmp', item.id, '')} >
                                                                {
                                                                    <img src={checkIcon} alt="user img" />
                                                                }
                                                            </div>
                                                        </div>
                                                        <span className='app-name'>{item.username}</span>
                                                    </li>
                                                ))
                                                : ''
                                        }
                                        {
                                            twitch.length > 0 ?
                                                twitch.map((item, index) => (
                                                    <li key={index} title={item.username}>
                                                        <div className={`broadcast-content-box-circle circle-img-wrap ${socialId.includes(item.id) ? 'active' : ''}`}>
                                                            <div className="circle-img">
                                                                <img src={twitchIcon} alt="user img" />
                                                                <div className='socialIcon'>
                                                                    <img src={twitchIcon} />
                                                                </div>
                                                            </div>

                                                            <div style={{ visibility: 'visible' }} className="circle-img-select" onClick={() => handleInputSocial('twitch', item.id, '')} >
                                                                {
                                                                    <img src={checkIcon} alt="user img" />
                                                                }
                                                            </div>
                                                        </div>
                                                        <span className='app-name'>{item.username}</span>
                                                    </li>
                                                ))
                                                : ''
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-box content-box--broadcast box-form-control purple-box text-white p-3">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label for="inputTitle">
                                        Title
                                        <span className="icon skyblue" title="Give a title to your broadcast">
                                            <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                                        </span>
                                    </label>
                                    <input type="text" value={broadCast.title} className="form-control" id="inputTitle"
                                        placeholder="Add Title" onChange={(e) => handleInputChange(e)} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label for="InputDescription">
                                        Description
                                        <span className="icon skyblue" title="Give a description to broadcast">
                                            <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                                        </span>
                                    </label>
                                    <textarea className="form-control" id="InputDescription" rows="4"
                                        value={broadCast.description} onChange={(e) => handleInputChange(e)}
                                        placeholder="Say something about this broadcast"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-box content-box--broadcast box-form-control purple-box mt-4">
                        <div className="form-group thumbnail-form">
                            <div className='d-flex justify-content-between'>
                                <label className="control-label">
                                    Thumbnail
                                    <span className="icon skyblue" title="Add a thumbnail to your broadcast">
                                        <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                                    </span>
                                </label>
                                <div className="box-tools alt">
                                    <button type="button"
                                        className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center">
                                        <label className="switch ms-3">
                                            <input type="checkbox" onChange={() => handleRemoveThumbnail(!thumbStatus)} />
                                            <span className="slider round"></span>
                                        </label>
                                    </button>
                                </div>
                            </div>

                            {
                                thumbStatus ?
                                    <>
                                        <div className="dropzone-wrapper">
                                            <div className="dropzone-desc">
                                                <p>
                                                    <span className="icon"><FontAwesomeIcon
                                                        icon={faCloudUploadAlt}></FontAwesomeIcon></span> Upload An Image</p>
                                            </div>
                                            <input disabled={loader} type="file" onChange={(e) => handleFileChange(e)} name="img_logo" className="dropzone" />
                                        </div>
                                        <div className="preview-zone hidden">
                                            <div className="box box-solid">
                                                <div className="box-header with-border">
                                                    {/* <div className="box-tools pull-right">
                                            <button type="button"
                                                    className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center">
                                                Remove
                                                <label className="switch ms-3">
                                                    <input type="checkbox" onChange={()=>handleRemoveThumbnail()} disabled={thumbnail.length?false:true} checked={thumbnail.length>0?'checked':''} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </button>
                                        </div> */}
                                                </div>
                                                <div className="box-body" style={{ position: 'relative' }}>
                                                    {
                                                        loader ? <div className="thumb-loader"><i className="fa fa-spinner fa-spin" /></div> : ''
                                                    }
                                                    {
                                                        thumbnail !== '' ?
                                                            <img width="100%" height="100%" src={thumbnail} alt={'thumbnail'} />
                                                            : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : ''
                            }
                        </div>
                    </div>
                    <div className="content-box content-box--broadcast purple-box form-group mt-4">
                        <div className="schedule-row d-flex justify-content-between">
                            <label className="ScheduleTextlabel" for="ScheduleText">
                                Schedule
                                <span className="icon skyblue" title="Schedule your broadcast to a later date">
                                    <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                                </span>
                            </label>
                            {/*<div className="form-check ml-2">*/}
                            {/*    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"/>*/}
                            {/*</div>*/}

                            <label className="switch">
                                <input type="checkbox" value={broadCast.schedule.schedule === 'true' ? false : true}
                                    checked={broadCast.schedule.schedule === 'true' ? true : false} name="schedule"
                                    onChange={(e) => handleScheduleChange(e)} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        {
                            broadCast.schedule.schedule === 'true' ?
                                <>
                                    <div className="alert alert-info">
                                        If you are scheduling in Facebook, please make sure it should be scheduled at least 10 minutes in advance.
                                    </div>

                                    <div className="scLater">
                                        <span>
                                            <select name="hours" onChange={(e) => handleScheduleChange(e)}>
                                                <option selected={broadCast.schedule.hours === '12' ? true : false} value="12">12</option>
                                                <option selected={broadCast.schedule.hours === '1' ? true : false} value="1">1</option>
                                                <option selected={broadCast.schedule.hours === '2' ? true : false} value="2">2</option>
                                                <option selected={broadCast.schedule.hours === '3' ? true : false} value="3">3</option>
                                                <option selected={broadCast.schedule.hours === '4' ? true : false} value="4">4</option>
                                                <option selected={broadCast.schedule.hours === '5' ? true : false} value="5">5</option>
                                                <option selected={broadCast.schedule.hours === '6' ? true : false} value="6">6</option>
                                                <option selected={broadCast.schedule.hours === '7' ? true : false} value="7">7</option>
                                                <option selected={broadCast.schedule.hours === '8' ? true : false} value="8">8</option>
                                                <option selected={broadCast.schedule.hours === '9' ? true : false} value="9">9</option>
                                                <option selected={broadCast.schedule.hours === '10' ? true : false} value="10">10</option>
                                                <option selected={broadCast.schedule.hours === '11' ? true : false} value="11">11</option>
                                            </select>
                                        </span>
                                        <span>
                                            <select name="minutes" onChange={(e) => handleScheduleChange(e)}>
                                                <option selected={broadCast.schedule.minutes === '05' ? true : false} value="05">05</option>
                                                <option selected={broadCast.schedule.minutes === '10' ? true : false} value="10">10</option>
                                                <option selected={broadCast.schedule.minutes === '15' ? true : false} value="15">15</option>
                                                <option selected={broadCast.schedule.minutes === '20' ? true : false} value="20">20</option>
                                                <option selected={broadCast.schedule.minutes === '25' ? true : false} value="25">25</option>
                                                <option selected={broadCast.schedule.minutes === '30' ? true : false} value="30">30</option>
                                                <option selected={broadCast.schedule.minutes === '35' ? true : false} value="35">35</option>
                                                <option selected={broadCast.schedule.minutes === '40' ? true : false} value="40">40</option>
                                                <option selected={broadCast.schedule.minutes === '45' ? true : false} value="45">45</option>
                                                <option selected={broadCast.schedule.minutes === '50' ? true : false} value="50">50</option>
                                                <option selected={broadCast.schedule.minutes === '55' ? true : false} value="55">55</option>
                                            </select>
                                        </span>
                                        <span>
                                            <select name="clock" onChange={(e) => handleScheduleChange(e)}>
                                                <option selected={studioData.schedule.clock === 'AM' ? true : false} value="AM">AM</option>
                                                <option selected={studioData.schedule.clock === 'PM' ? true : false} value="PM">PM</option>
                                            </select>
                                        </span>
                                        <span>{new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1]}</span>
                                    </div>

                                    <div className="mt-3">
                                        <Calendar
                                            value={dateState}
                                            onChange={(e) => handleChangeDate(e)}
                                        />
                                    </div>
                                </>
                                : ''
                        }
                    </div>
                    {/* <div className="scheduleTime content-box content-box--broadcast purple-box form-group mt-4">
                                                    <label for="ScheduleTime">Schedule start time</label>
                                                    <span className="icon skyblue"><FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon></span>
                                                    <div className="row">
                                                        <div className="col-12 col-lg-12 col-xl-3 my-2 my-xl-0">
                                                            <button className="btn btn-secondary today" type="button">
                                                                Today
                                                            </button>
                                                        </div>
                                                        <div className="col-12 col-lg-6 col-xl-2 px-xl-1 my-2 my-xl-0">
                                                            <div className="dropdown">
                                                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    05
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                    <li><button className="dropdown-item" type="button">Action</button></li>
                                                                    <li><button className="dropdown-item" type="button">Another action</button></li>
                                                                    <li><button className="dropdown-item" type="button">Something else here</button></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12  col-lg-6 col-xl-2 px-xl-1 my-2 my-xl-0">
                                                            <div className="dropdown">
                                                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    25
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                    <li><button className="dropdown-item" type="button">Action</button></li>
                                                                    <li><button className="dropdown-item" type="button">Another action</button></li>
                                                                    <li><button className="dropdown-item" type="button">Something else here</button></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 col-xl-2 px-xl-1 my-2 my-xl-0">
                                                            <div className="dropdown">
                                                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    PM
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                    <li><button className="dropdown-item" type="button">AM</button></li>
                                                                    <li><button className="dropdown-item" type="button">PM</button></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 col-xl-3 px-xl-1 my-2 my-xl-0">
                                                            <div className="dropdown">
                                                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    GMT+10
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                    <li><button className="dropdown-item" type="button">Action</button></li>
                                                                    <li><button className="dropdown-item" type="button">Another action</button></li>
                                                                    <li><button className="dropdown-item" type="button">Something else here</button></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                    {/* <Button variant="blue w-100 p-3">
                                                    <span className="mr-2"><FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon></span>
                                                    Create Broadcast</Button>{' '} */}
                </form>
            </div>
        </Tab.Pane>
    );
};

export default Broadcast;
