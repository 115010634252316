import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import VideoCard from "./VideoCard";
import Footer from "../Footer";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../../global/global";
import SweetAlert from "react-bootstrap-sweetalert";
import {setAlert} from "../../actions/alert";
import Alert from "../Alert";

const Projects = () => {

    const auth = useSelector(state => state.auth);
    const brandName = useSelector(state => state.rebrand.data);
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [projects, setProjects] = useState([]);
    const [q, setQ] = useState('');
    const [deleteId, setDeleteId] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [buttonText, setButtonText] = useState('Delete');
    const [searched, setSearched] = useState(false);
    const [memberships, setMemberships] = useState([]);

    const onPopupOpen = (id) => {
        setDeleteId(id);
        setShowDelete(true);
    }

    const onConfirm = () => {
        deleteCampaign();
    }

    const deleteCampaign = () => {
        setButtonText('Deleting...');
        axios({
            method: "POST",
            url: `${baseURL}delete-broadcast`,
            data: {id: deleteId},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                fetchProjects();
                dispatch(setAlert('Broadcast deleted successfully', 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButtonText('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButtonText('Delete');
        })
    }

    const handleSearch = (e) => {
        setSearched(true);
        setQ(e.target.value)
    }

    const search = (projects) => {
        return projects.filter(
            project => project.title.toLowerCase().indexOf(q.toLowerCase()) > -1
        );
    }

    const fetchProjects = () => {
        setLoader(true);

        axios({
            method: 'POST',
            url: `${baseURL}fetch-user-broadcast`,
            data: {},
            headers: {'Content-Type' : 'application/json', 'Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setProjects(res.data.data)
            }

            setLoader(false);
        }).catch(err=>{
            setLoader(false);
        })
    }

    useEffect(()=>{
       fetchProjects();
    },[])

    useEffect(()=>{

        if(auth.user && auth.user.membership !== null)
            setMemberships(auth.user.membership.split('__'))

    },[auth.user.membership])

    return(
        <>
            <Alert/>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'StreamReel'} | Projects </title>
            </Helmet>
            <Navbar/>

            <section className="project-banner">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-5  d-flex justify-content-lg-start justify-content-center">
                            <div className="project-header-name d-flex  text-white">
                                <div className="project-item d-flex align-items-center">
                                    <h4 className="m-0">ALL Broadcasts</h4>
                                </div>
                                <div className="project-conut d-flex align-items-center">
                                    <h6 className="m-0">{search(projects).length} Broadcast</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7  d-flex justify-content-lg-end justify-content-center">
                            <div className="project-button d-flex text-white align-items-center ">
                                <div className="project-input-box">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fas fa-search"></i></span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Search Broadcasts" onChange={(e)=>handleSearch(e)} />
                                    </div>
                                </div>
                                {/* <div className="project-create-btn">
                                    <button type="button" className="btn-change6"><span className="mr-2">
                                        <i className="fas fa-plus-circle"></i></span> Create New Project
                                    </button>
                                </div> */}
                            </div>
                        </div>
                        <span className="bottom-line"></span>
                    </div>

                    <div className="row mt-5">
                        <div className="my-video-projects">
                            <div className="row gx-4">
                                {
                                    projects.length > 0 ?
                                        search(projects).map(item=>(
                                            <VideoCard
                                                key={item.id}
                                                id={item.id}
                                                title={item.title}
                                                thumbnail={item.thumbnail}
                                                status={item.status}
                                                created={item.created}
                                                url={item.record_url}
                                                schedule={JSON.parse(item.schedule)}
                                                memberships={memberships}
                                                onPopupOpen={onPopupOpen}
                                            />
                                        ))
                                    : ''
                                }
                            </div>

                            {
                                 loader===false && search(projects).length === 0 && q!=='' ?
                                    <h4 className="text-center text-white">No broadcasts for this "{q}" name</h4>
                                : ''
                            }

                            {
                                loader && projects.length === 0 ?
                                    <h4 className="text-center text-white"><i className="fa fa-spinner fa-spin mr-2"/> Fetching Broadcasts</h4>
                                : ''
                            }

                            {
                                loader === false && projects.length === 0 && q==='' ?
                                    <h4 className="text-center text-white">You didn't created any broadcasts yet.</h4>
                                : ''
                            }


                        </div>
                    </div>

                </div>

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={buttonText}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="light"
                    title="Are you sure?"
                    onConfirm={() => onConfirm()}
                    onCancel={() => setShowDelete(false)}
                    focusCancelBtn
                    show={showDelete}
                >
                    You want to delete this Broadcast
                </SweetAlert>

            </section>

            <Footer/>
        </>
    )
}

export default Projects;