import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faAd, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap';
import checkIcon from "../../images/check.png"
import fbLogo from "../../images/logo-facebook.svg";
import ytLogo from "../../images/youtube-round.png";
import fbLogo2 from "../../images/facebook-round.png";
import rtmpIcon from "../../images/rmtp-icon.png";
import twitchIcon from "../../images/TWITCH-Default.png";
import { useDispatch, useSelector } from "react-redux";
import { title } from "react-bootstrap-sweetalert/dist/styles/SweetAlertStyles";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { baseURL } from "../../global/global";
import { Link, useHistory } from "react-router-dom";
import { addStudioData } from "../../actions/studioAction";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert";


const BroadcastSettings = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const socialData = useSelector(state => state.social);

    const [loader, setLoader] = useState(false);
    const [socialId, setSocialId] = useState([]);
    const [facebook, setFacebook] = useState([]);
    const [youtube, setYoutube] = useState([]);
    const [twitch, setTwitch] = useState([]);
    const [rtmp, setRtmp] = useState([]);
    const [hours, setHours] = useState('12');
    const [minutes, setMinutes] = useState('05');
    const [broadCast, setBroadCast] = useState({
        title: '',
        description: '',
        social_platform: [],
        schedule: {
            schedule: 'false',
            date: '',
            clock: 'AM',
            hours: '',
            minutes: '',
            raw_date: ''
        },
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
    const [dateState, setDateState] = useState(new Date());

    const handleChangeDate = (e) => {
        setDateState(e);

        setBroadCast({
            ...broadCast, schedule: {
                ...broadCast.schedule, date: e.toLocaleString().split(',')[0], raw_date: e.toString()
            }
        })
    }

    const handleScheduleChange = (e) => {

        setBroadCast({ ...broadCast, schedule: { ...broadCast.schedule, [e.target.name]: e.target.value } })
    }

    const handleInputSocial = (type, social_id, rtmp) => {

        if (socialId.includes(social_id)) {

            let tempArr = broadCast.social_platform.filter(item => item.social_id !== social_id);
            setBroadCast({ ...broadCast, social_platform: tempArr });
            setSocialId(socialId.filter(item => item !== social_id))

        } else {
            let socialObj = { type, rtmp, social_id, selected: true }

            setSocialId([...socialId, social_id]);
            setBroadCast({ ...broadCast, social_platform: [...broadCast.social_platform, socialObj] });
        }
    }

    const handleInputChange = (e) => {
        setBroadCast({ ...broadCast, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        if (broadCast.schedule.schedule === 'true') {
            broadCast.schedule.date += ' ' + hours + ':' + minutes + ':00'
            broadCast.schedule.hours = hours
            broadCast.schedule.minutes = minutes
        }

        axios({
            method: 'POST',
            url: `${baseURL}create-broadcast`,
            data: broadCast,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(addStudioData(res.data.broadcast));
                history.push(`/studio?id=${res.data.broadcast.id}`);
            } else {
                dispatch(setAlert(res.data.message, 'danger'))
            }
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }


    useEffect(() => {
        setFacebook(socialData.facebook);
        setYoutube(socialData.youtube);
        setTwitch(socialData.twitch);
        setRtmp(socialData.customRtmp);
    }, [socialData])

    useEffect(() => {
        setBroadCast({ ...broadCast, schedule: { ...broadCast.schedule, date: `${(new Date().getMonth()) + 1}/${(new Date().getDate())}/${(new Date().getFullYear())}` } })
    }, [])

    return (
        <>
            <Helmet>
                <title>Broadcast Settings | Stream</title>
            </Helmet>
            <Navbar />

            <Alert />
            <div className='broadSetting'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>

                            <div className="broadcast-content">
                                <form onSubmit={(e) => handleSubmit(e)} method="POST">
                                    <div className="content-box content-box--broadcast box-form-control purple-box text-white p-3 mb-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <h6>Broadcast To</h6>
                                            </div>
                                            <div className="col-12">
                                                <div>
                                                    {
                                                        facebook.length === 0 && youtube.length === 0 && rtmp.length === 0 ?
                                                            <div className="alert alert-info">
                                                                It seems you do not have any Social Account connected. <br /> Please connect one by <Link style={{ color: '#05a1eb' }} to="/integration">click here</Link>
                                                            </div>
                                                            : ''
                                                    }
                                                    <ul className="broadcast-content-box">
                                                        {
                                                            facebook.length > 0 ?
                                                                facebook.map((item, index) => (
                                                                    <li key={index}>
                                                                        <div className={`broadcast-content-box-circle circle-img-wrap ${socialId.includes(item.id) ? 'active' : ''}`}>
                                                                            <div className="circle-img">
                                                                                <img src={item.image} alt="user img" />
                                                                                <div className='socialIcon'>
                                                                                    <img src={fbLogo2} />
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ visibility: 'visible' }} className="circle-img-select" onClick={() => handleInputSocial('facebook', item.id, '')}>
                                                                                {
                                                                                    <img src={checkIcon} alt="user img" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <span className='app-name'>{item.username}</span>
                                                                    </li>
                                                                ))
                                                                : ''
                                                        }
                                                        {
                                                            youtube.length > 0 ?
                                                                youtube.map((item, index) => (
                                                                    <li key={index} title={item.username}>
                                                                        <div className={`broadcast-content-box-circle circle-img-wrap ${socialId.includes(item.id) ? 'active' : ''}`}>
                                                                            <div className="circle-img">
                                                                                <img src={item.image} alt="user img" />
                                                                                <div className='socialIcon'>
                                                                                    <img src={ytLogo} />
                                                                                </div>
                                                                            </div>

                                                                            <div style={{ visibility: 'visible' }} className="circle-img-select" onClick={() => handleInputSocial('youtube', item.id, '')} >
                                                                                {
                                                                                    <img src={checkIcon} alt="user img" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <span className='app-name'>{item.username}</span>
                                                                    </li>
                                                                ))
                                                                : ''
                                                        }
                                                        {
                                                            rtmp.length > 0 ?
                                                                rtmp.map((item, index) => (
                                                                    <li key={index} title={item.username}>
                                                                        <div className={`broadcast-content-box-circle circle-img-wrap ${socialId.includes(item.id) ? 'active' : ''}`}>
                                                                            <div className="circle-img">
                                                                                <img src={item.image} alt="user img" />
                                                                                <div className='socialIcon'>
                                                                                    <img src={rtmpIcon} />
                                                                                </div>
                                                                            </div>

                                                                            <div style={{ visibility: 'visible' }} className="circle-img-select" onClick={() => handleInputSocial('rtmp', item.id, '')} >
                                                                                {
                                                                                    <img src={checkIcon} alt="user img" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <span className='app-name'>{item.username}</span>
                                                                    </li>
                                                                ))
                                                                : ''
                                                        }
                                                        {
                                                            twitch.length > 0 ?
                                                                twitch.map((item, index) => (
                                                                    <li key={index} title={item.username}>
                                                                        <div className={`broadcast-content-box-circle circle-img-wrap ${socialId.includes(item.id) ? 'active' : ''}`}>
                                                                            <div className="circle-img">
                                                                                <img src={twitchIcon} alt="user img" />
                                                                                <div className='socialIcon'>
                                                                                    <img src={twitchIcon} />
                                                                                </div>
                                                                            </div>

                                                                            <div style={{ visibility: 'visible' }} className="circle-img-select" onClick={() => handleInputSocial('twitch', item.id, '')} >
                                                                                {
                                                                                    <img src={checkIcon} alt="user img" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <span className='app-name'>{item.username}</span>
                                                                    </li>
                                                                ))
                                                                : ''
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-box content-box--broadcast box-form-control purple-box text-white p-3">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label for="inputTitle">Title</label>
                                                    <input required type="text" className="form-control" name="title" value={broadCast.title}
                                                        placeholder="Add Title" onChange={(e) => handleInputChange(e)} />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="InputDescription">Description</label>
                                                    <textarea required class="form-control" id="InputDescription" rows="4" name="description" value={broadCast.description}
                                                        placeholder="Say something about this broadcast" onChange={(e) => handleInputChange(e)}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-box content-box--broadcast purple-box form-group mt-4">
                                        <div className="schedule-row d-flex justify-content-between">
                                            <div className='d-flex'>
                                                <label className="ScheduleTextlabel" for="ScheduleText">Schedule</label>
                                                {/*<div class="form-check ml-2">*/}
                                                {/*    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>*/}
                                                {/*</div>*/}
                                                <span title="Schedule to a Later Date" className="icon skyblue"><FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon></span>
                                            </div>
                                            <label className="switch">
                                                <input type="checkbox" value={broadCast.schedule.schedule === 'true' ? false : true} checked={broadCast.schedule.schedule === 'true' ? true : false} name="schedule" onChange={(e) => handleScheduleChange(e)} />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        {
                                            broadCast.schedule.schedule === 'true' ?
                                                <>

                                                    <div className="alert alert-info">
                                                        If you are scheduling in Facebook, please make sure it should be scheduled at least 10 minutes in advance.
                                                    </div>

                                                    <div className="scLater">
                                                        <span>
                                                            <select name="hours" onChange={(e) => setHours(e.target.value)}>
                                                                <option value="12">12</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                                <option value="11">11</option>
                                                            </select>
                                                        </span>
                                                        <span>
                                                            <select name="minutes" onChange={(e) => setMinutes(e.target.value)}>
                                                                <option value="05">05</option>
                                                                <option value="10">10</option>
                                                                <option value="15">15</option>
                                                                <option value="20">20</option>
                                                                <option value="25">25</option>
                                                                <option value="30">30</option>
                                                                <option value="35">35</option>
                                                                <option value="40">40</option>
                                                                <option value="45">45</option>
                                                                <option value="50">50</option>
                                                                <option value="55">55</option>
                                                            </select>
                                                        </span>
                                                        <span>
                                                            <select name="clock" onChange={(e) => handleScheduleChange(e)}>
                                                                <option value="AM">AM</option>
                                                                <option value="PM">PM</option>
                                                            </select>
                                                        </span>
                                                        <span>{new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1]}</span>
                                                    </div>

                                                    <div className="mt-3">
                                                        <Calendar
                                                            value={dateState}
                                                            onChange={(e) => handleChangeDate(e)}
                                                        />
                                                    </div>
                                                </>
                                                : ''
                                        }
                                    </div>

                                    <Button disabled={loader} type="submit" variant="blue w-100 p-3 btOrg">
                                        <span className="mr-2">
                                            {loader ? <i className="fa fa-spinner fa-spin" /> : <FontAwesomeIcon icon={faPlusCircle} />}
                                        </span>
                                        Create Broadcast
                                    </Button>{' '}
                                </form>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default BroadcastSettings;
