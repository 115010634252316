import React, {useState, useEffect} from 'react';
import GuestStudioDetails from "./GuestStudioDetails";
import GuestCamMicSettings from "./GuestCamMicSettings";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import queryString from "query-string";
import axios from "axios";
import {baseURL} from "../../global/global";
import {useDispatch, useSelector} from "react-redux";
import {setAlert} from "../../actions/alert";
import {addGuestStudioData} from "../../actions/guestStudioAction";
import Alert from "../Alert";
import GuestNav from "./GuestNav";

const GuestStudio = ({location}) => {
    const dispatch = useDispatch();
    const [hostStream, setHostStream] = useState({stream:false,name:''});
    const [openStudioDetails, setOpenStudioDetails] = useState(false);

    const broadcastID = queryString.parse(location.search).id

    let studio = null;
    if(openStudioDetails){
        studio = <GuestStudioDetails hostStream={hostStream} />
    }
    else{
        studio = <GuestCamMicSettings setHostStream={setHostStream} setOpenStudioDetails={setOpenStudioDetails}/>
    }

    useEffect(()=>{
        if(broadcastID !== undefined){
            axios({
                method: 'POST',
                url: `${baseURL}fetch-guest-broadcast`,
                data: {id: broadcastID},
                headers: {'Content-Type': 'application/json'}
            }).then(res=>{
                if(res.data.status === true){
                    dispatch(addGuestStudioData(res.data.data));
                    // Update Guest Studio Redux
                }else{
                    dispatch(setAlert(res.data.message, 'success'))
                }
            }).catch(err=>{

            })
        }
    },[broadcastID])

    return (
        <>
            <Alert/>
            <Helmet>
                <title>StreamReel Guest Studio | Stream</title>
            </Helmet>
            <GuestNav/>
            {studio}
        </>
    );
};

export default GuestStudio;
