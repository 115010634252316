import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { changeProfile } from "../../actions/authAction";
import Alert from "../Alert";
import editImg from "../../images/editBt.png"

const ProfileImage = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [memberships, setMemberships] = useState([]);
    const [profileStatus, setProfileStatus] = useState(false);

    const [userProfile, setUserProfile] = useState({
        id: auth.user.id,
        file: ''
    });

    const onImageChange = (e) => {
        if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {

            if (e.target.files[0].size < 5000000) {
                e.preventDefault();
                setProfileStatus(true);

                const form = new FormData();
                form.append('user_id', userProfile.id);
                form.append('file', e.target.files[0]);

                dispatch(changeProfile(form, setProfileStatus));
            } else {
                swal("Oops!", "Max allowed size 5MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }

    useEffect(() => {

        if (auth.user && auth.user.membership !== null)

            setMemberships(auth.user.membership.split('__'))

    }, [auth.user.membership])

    return (
        <>
            <Alert />
            <div className="row">
                <div className="col-md-12">
                    <div className="user-details d-flex justify-content-center align-items-center flex-column">
                        <div className="user-img">
                            <img src={auth.user.image} alt="user-img" width="100px" height="100px" />
                            <div className={`${profileStatus === false ? 'hide-social' : ''} my-account-loader`}>
                                <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: "30px" }} />
                            </div>
                            <div className="custom-file">
                                <input type="file" onChange={(e) => onImageChange(e)}
                                    className="custom-file-input" accept="image/*"
                                />
                            </div>
                            <div className="imgEdit">
                                <img src={editImg} />
                            </div>
                        </div>
                        <h5 className="mt-3">{auth.user.name}</h5>
                        <button type="button" className="btn btn-bundle primary-gradient mt-2">
                            <h6>
                                {
                                    memberships.length ? memberships[memberships.length - 1].replaceAll('_', ' ').toUpperCase() : ''
                                }
                            </h6>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileImage;