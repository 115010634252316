import React, {useRef, useState, useEffect} from 'react';
import profileImage from "../../../images/profile.jpg";
import { memo } from "react";
const Preview_1 = ({userVideoAudio, hostStream, peers, setFinalStream, peersStream}) => {
    const videoRef = useRef(null);
    const profileRef = useRef(null);
    const profileCanvasRef = useRef(null);

    const [showVideo, setShowVideo] = useState(0);
    const [peerIndex, setPeerIndex] = useState(false);


    useEffect(() => {
        if(userVideoAudio.localUser.status){

            if(userVideoAudio.localUser.video && hostStream !== false){
                setShowVideo(1)
            }else{
                setShowVideo(0)
            }
        }else{
            for (const key in userVideoAudio) {
                if (userVideoAudio.hasOwnProperty(key) && key !== 'localUser') {
                    const peer = userVideoAudio[key];
                    if(peer.status){
                        setShowVideo(2)
                        setPeerIndex(key)
                    }
                }
            }
        }

    },[JSON.stringify(userVideoAudio)])

    useEffect(() => {
        if(showVideo === 1){
            // show user video
            videoRef.current.srcObject = hostStream;
            setFinalStream(hostStream);
        }
        if(showVideo === 2){
            // show peer video
            if(peerIndex){
                const lp = peersStream.find(p => p.name === peerIndex)
                if(lp !== undefined){
                    videoRef.current.srcObject = lp.stream;
                    setFinalStream(lp.stream);
                }

            }
        }
        if(showVideo === 0 && profileCanvasRef !== null ){
            // show Image
            //setTimeout(() => {
            const canvas = profileCanvasRef.current;
            const context = canvas.getContext("2d");
            context.drawImage(profileRef.current, 0, 0, 1280, 720);
            const profileCanvasStream = canvas.captureStream();
            setFinalStream(profileCanvasStream);
            //},1000)
        }
    },[showVideo])



    return (
        <div className='defaultImg-wrap'>
            {showVideo === 1 || showVideo === 2 ?  showVideo === 1 ? <video ref={videoRef} autoPlay playsInline muted/> : <video ref={videoRef} autoPlay playsInline />:
                <>
                    <div style={{display:'none'}}>
                        <canvas ref={profileCanvasRef} width="1280" height="720" />
                    </div>
                    <img ref={profileRef} className='defaultImg' src={profileImage} alt="Profile Image"/>
                </>
            }
        </div>
    );
};

export default memo(Preview_1);
