import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrophone, faMicrophoneSlash, faUser, faVideoSlash, faVideo} from "@fortawesome/free-solid-svg-icons";
import liveVideo from "../../images/profile.jpg";
import {useDispatch} from "react-redux";

const GuestSelfUser = ({stream, localUser, name, handlePeerActive}) => {
    const dispatch = useDispatch();
    const videoRef = useRef(null);
    // local user has the option of enable disable
    // const [selfUser, setSelfUser] = useState({
    //     audio:studio.host.audio,
    //     video:studio.host.video
    // })

    useEffect(() => {
        if(videoRef !== null && localUser.video){
            if(stream !== false){
                videoRef.current.srcObject = stream
            }
        }

    }, [stream,localUser.video]);

    const handleActive = (status) => {
        handlePeerActive('localUser',status)
    }

    const handleMute = (status) => {
        dispatch({type: 'UPDATE_GUEST_AUDIO_STATUS', payload: status})
    }

    const handleStopCam = (status) => {
        dispatch({type: 'UPDATE_GUEST_VIDEO_STATUS', payload: status})
    }
    return (
        <li className="go-live-box-footer-group">
            <div className="go-live-box-footer-group-box">
                <div
                    className="go-live-box-footer-group-box-options d-flex justify-content-between">
                    {localUser.audio ? <button onClick={() => handleMute(false)} type="button" className="btn btn-light" >
                        <FontAwesomeIcon icon={faMicrophone}> </FontAwesomeIcon>
                    </button> : <button onClick={() => handleMute(true)} type="button" className="btn btn-light" >
                        <FontAwesomeIcon icon={faMicrophoneSlash}> </FontAwesomeIcon>
                    </button>}

                    {localUser.video ? <button onClick={() => handleStopCam(false)} type="button" className="btn btn-light" >
                        <FontAwesomeIcon  icon={faVideo}> </FontAwesomeIcon>
                    </button> : <button onClick={() => handleStopCam(true)} type="button" className="btn btn-light" >
                        <FontAwesomeIcon  icon={faVideoSlash}> </FontAwesomeIcon>
                    </button>}


                </div>

                <div className="go-live-box-footer-group-box-img">
                    {localUser.video && stream !== false ? <video ref={videoRef} style={{width:'160px'}} playsInline
                                                                  autoPlay muted/>: <img src={liveVideo} alt="live video" className="img-fluid"/>}
                </div>

                <div className="go-live-box-footer-group-box-name d-flex">
                    <span className="icon">
                        <FontAwesomeIcon icon={faUser}> </FontAwesomeIcon>
                    </span>
                    <p>{name}</p>
                </div>

            </div>
        </li>
    );
};

export default GuestSelfUser;
