import React, {useEffect, useState} from "react";
import MusicFiles from "./MusicFiles";
import axios from "axios";
import {baseURL} from "../../../global/global";
import {useSelector} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

const MusicLibrary = ({selectedAudio, currentUrl, setCurrentUrl, setSelectedAudio, loop, volume}) => {

    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [audioFiles, setAudioFiles] = useState([]);
    const [page, setPage] = useState(1);

    const fetchMusic = (page = 1) => {
        setLoader(true);

        axios({
            method: 'POST',
            url: `${baseURL}load-library-audio`,
            data: {page_number: page},
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setAudioFiles(audioFiles.concat(res.data.data));
                setPage(page+1);
            }

            setLoader(false);
        }).catch(err=>{
            setLoader(false);
        })
    }

    useEffect(()=>{
        fetchMusic();
    },[])

    return(
        <>
            <div className="content-box content-box--broadcast box-form-control purple-box mt-4"
                 id="scrollableAudio" 
                //  style={{ overflowX: "hidden", height: '600px' }}
                 >
                <InfiniteScroll
                    dataLength={audioFiles.length} //This is important field to render the next data
                    next={() => fetchMusic(page)}
                    hasMore={true}
                    scrollableTarget="scrollableAudio"
                    className="row"
                >
                    {
                        audioFiles.length > 0 ?
                            audioFiles.map((item, index)=>(
                                <MusicFiles
                                    name={item.title}
                                    url={item.url}
                                    currentUrl={currentUrl}
                                    setCurrentUrl={setCurrentUrl}
                                    selectedAudio={selectedAudio}
                                    setSelectedAudio={setSelectedAudio}
                                    loop={loop}
                                    volume={volume}
                                    key={index}
                                />
                            ))
                        : ''
                    }

                    {
                        loader ? <p style={{fontSize: '24px'}} className="text-center"><i className="fa fa-spinner fa-spin"/></p> : ''
                    }
                </InfiniteScroll>
            </div>
        </>
    )
}

export default React.memo(MusicLibrary);