const initialState = {
    id: false,
    roomId: false,
    live: {
        type:'live',
        enable:false
    },
    title: '',
    host_name: '',
    host: {
        video: true,
        audio: true
    },
    description: '',
    social_platform: [],
    thumbnail: '',
    customization: {
        color: '#FF3D38',
        text_style: 'default',
        logo: [],
        overlay: [],
        video: [],
        background: []
    },
    music: [],
    banner: [],
    comment: [],
    schedule: {
        schedule: false,
        date: '',
        clock: ''
    },
    timezone: '',
    blobVideo: []
}

const checkCustomize = (data) => {
    if(data !== '')
        return data;
    else
        return initialState.customization
}

export default function (state=initialState, action) {
    switch (action.type) {

        case 'ADD_STUDIO_DATA':
            return{
                ...state,
                id: action.payload.id,
                title: action.payload.title,
                host_name: action.payload.host_name,
                roomId: action.payload.roomId,
                description: action.payload.description,
                social_platform: action.payload.social_platform,
                thumbnail: action.payload.thumbnail,
                customization: checkCustomize(action.payload.customization),
                music: action.payload.music,
                banner: action.payload.banner,
                comment: action.payload.comment,
                timezone: action.payload.timezone,
                schedule: action.payload.schedule
            }

        case 'UPDATE_CUSTOMIZE_DATA':
            return {
                ...state,
                customization: action.payload
            }

        case 'UPDATE_CUSTOMIZE_TEXT_COLOR':
            return {
                ...state,
                customization: {
                    ...state.customization,
                    color: action.payload
                }
            }

        case 'UPDATE_CUSTOMIZE_TEXT_STYLE':
            return {
                ...state,
                customization: {
                    ...state.customization,
                    text_style: action.payload
                }
            }

        case 'UPDATE_ROOM_ID':
            return {
                ...state,
                roomId: action.payload
            }

        case 'UPDATE_BLOB_VIDEO':
            return {
                ...state,
                blobVideo: [...state.blobVideo, action.payload]
            }

        case 'REMOVE_BLOB_VIDEOS':
            return {
                ...state,
                blobVideo: []
            }

        case 'UPDATE_BLOB_STATUS':
            return {
                ...state,
                blobVideo: action.payload
            }

        case 'UPDATE_LOGO':
            return {
                ...state,
                customization: {
                    ...state.customization,
                    logo: [...state.customization.logo, action.payload]
                }
            }

        case 'UPDATE_OVERLAY':
            return {
                ...state,
                customization: {
                    ...state.customization,
                    overlay: [...state.customization.overlay, action.payload]
                }
            }

        case 'UPDATE_VIDEO':
            return {
                ...state,
                customization: {
                    ...state.customization,
                    video: [...state.customization.video, action.payload]
                }
            }

        case 'UPDATE_BACKGROUND':
            return {
                ...state,
                customization: {
                    ...state.customization,
                    background: [...state.customization.background, action.payload]
                }
            }

        case 'UPDATE_AUDIO_STATUS':
            return {
                ...state,
                host: {
                    ...state.host,
                    audio: action.payload
                }
            }

        case 'UPDATE_VIDEO_STATUS':
            return {
                ...state,
                host: {
                    ...state.host,
                    video: action.payload
                }
            }

        case 'ADD_STUDIO_LOGO':
            return {
                ...state,
                customization: {
                    ...state.customization,
                    logo: action.payload
                }
            }

        case 'ADD_STUDIO_OVERLAY':
            return {
                ...state,
                customization: {
                    ...state.customization,
                    overlay: action.payload
                }
            }

        case 'ADD_STUDIO_BACKGROUND':
            return {
                ...state,
                customization: {
                    ...state.customization,
                    background: action.payload
                }
            }

        case 'ADD_STUDIO_VIDEO':
            return {
                ...state,
                customization: {
                    ...state.customization,
                    video: action.payload
                }
            }

        case 'UPDATE_LIVE_STATUS':
            return {
                ...state,
                live: action.payload
            }

        case 'UPDATE_BANNER_STATUS':
            return {
                ...state,
                banner: action.payload
            }

        default:
            return state

    }
}