import React, {useEffect, useRef, useState} from 'react';
import {Col, Nav, Row, Tab} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDoubleRight,
    faFilm,
    faMicrophone, faUser,
    faVideo
} from "@fortawesome/free-solid-svg-icons";
import Guest from "./GuestChat";
import GuestCameraSettings from "./GuestCameraSettings";
import GuestTimelineUsers from "./GuestTimelineUsers";
import {useSelector} from "react-redux";
import GuestChat from "./GuestChat";
import SimplePeer from "simple-peer";
import socket from "../../global/socketExitConnection";
import GuestSelfUser from "./GuestSelfUser";

let peer = false;
let localStream = false;
const GuestStudioDetails = ({hostStream}) => {
    localStream = hostStream.stream;

    const studioData = useSelector(state => state.guestStudio);



    const [userVideoAudio, setUserVideoAudio] = useState({
        localUser: { video: studioData.guest.video, audio: studioData.guest.audio, status:true },
    });

    const [totalFeeds, setTotalFeeds] = useState([]);
    const [mergedStream, setMergedStream] = useState(false);

    const addFeed = (feedObject) => {
        if(feedObject.feedType === 'screen'){
            if(peer !== false){
                const track = feedObject.stream.getTracks()[0];
                peer.addTrack(track,localStream);
            }
            setTotalFeeds([...totalFeeds,feedObject]);
        }

    }

    // Remove Feed
    const removeFeed = () => {
        //const feedObject = totalFeeds.filter(item => item.hostType === 'self' && item.feedType === 'screen');
        setTotalFeeds(totalFeeds);
    }
    const guestPreview = useRef(null);
    const peersRef = useRef([]);
    const [peers, setPeers] = useState([]);


    // add a new Peer
    function addPeer(incomingSignal, callerId, stream) {
        peer = new SimplePeer({
            initiator: false,
            trickle: false,
            stream,
        });

        peer.on('signal', (signal) => {
            socket.emit('BE-accept-call', { signal, to: callerId });
            // socket.emit('BE-accept-call', { signal, callerId });
        });

        peer.on('disconnect', () => {
            peer.destroy();
        });

        peer.signal(incomingSignal);

        peer.on('stream', (streamLocal) => {
            setMergedStream(streamLocal)
        });

        return peer;
    }

    // find Peer Index
    function findPeer(id) {
        return peersRef.current.find((p) => p.peerID === id);
    }

    // create a new Peer
    function createPeer(userToCall, caller, stream) {
        const peer = new SimplePeer({
            initiator: true,
            trickle: false,
            stream,
        });

        peer.on('signal', (signal) => {
            socket.emit('BE-call-user', {
                roomId:studioData.roomId,
                userToCall: userToCall,
                from: caller,
                signal,
            });
        });

        peer.on('disconnect', () => {
            peer.destroy();
        });

        return peer;
    }



    // will fire once user is muted / stopCamp
    useEffect(() =>{
        if(localStream !== false){
            localStream.getAudioTracks()[0].enabled = studioData.guest.audio;
            localStream.getVideoTracks()[0].enabled = studioData.guest.video;
        }
        setUserVideoAudio((preList) => {
            return {
                ...preList,
                localUser: { ...userVideoAudio.localUser, video:studioData.guest.video, audio:studioData.guest.audio },
            };
        });
    },[studioData.guest.audio,studioData.guest.video])

    // will fire on studio unmount
    useEffect(() => {
        return () => {
            // emit for unmount component
            socket.emit("streamEnd", {broadcastId: studioData.id, broadcastType: studioData.live.type});
            socket.disconnect();

            totalFeeds.forEach(feed => {
                if(feed.stream !== false){
                    feed.stream.getTracks().forEach(track => {console.log('Exiting Studio'); track.stop()})
                }
            })

            if(hostStream.stream !== false) {
                hostStream.stream.getTracks().forEach(track => {console.log('Exiting Studio'); track.stop()})
            }
        };
    }, []);


    useEffect(() =>{
        if(mergedStream !== false){
            guestPreview.current.srcObject = mergedStream;
            guestPreview.current.play().then().catch();
        }
    },[mergedStream])

// socket connection
    useEffect(() => {
        socket.emit("joinRoom",{roomId:studioData.roomId, userName:hostStream.name});

        socket.on('FE-user-join', (users) => {
            // all users
            const peers = [];
            users.forEach((user) => {
                let { userName, video, audio,socketId} = user.info;

                if (userName !== hostStream.name) {
                    // const peer = createPeer(socketId, socket.id, localStream);
                    const peer = false;

                    peer.userName = userName;
                    peer.peerID = socketId;

                    peersRef.current.push({
                        peerID: socketId,
                        peer,
                        userName,
                    });
                    peers.push(peer);

                    setUserVideoAudio((preList) => {
                        return {
                            ...preList,
                            [peer.userName]: { video, audio },
                        };
                    });

                }
                else{
                    console.log('user already exists')
                }
            });

            setPeers(peers);
        });

        socket.on('FE-receive-call', ({ signal, from, info }) => {
            let { userName, video, audio } = info;
            const peerIdx = findPeer(from);

            if (!peerIdx) {
                const peer = addPeer(signal, from, localStream);

                peer.userName = userName;

                peersRef.current.push({
                    peerID: from,
                    peer,
                    userName: userName,
                });
                setPeers((users) => {
                    return [...users, peer];
                });
                setUserVideoAudio((preList) => {
                    return {
                        ...preList,
                        [peer.userName]: { video, audio },
                    };
                });
            }
        });


        socket.on('FE-call-accepted', ({ signal, answerId }) => {
            const peerIdx = findPeer(answerId);
            peerIdx.peer.signal(signal);
        });


        socket.on('FE-user-leave', ({ userId, userName }) => {
            const peerIdx = findPeer(userId);
            peerIdx.peer.destroy();
            setPeers((users) => {
                users = users.filter((user) => user.peerID !== peerIdx.peer.peerID);
                return [...users];
            });
            peersRef.current = peersRef.current.filter(({ peerID }) => peerID !== userId );
        });


        socket.on('FE-toggle-camera', ({ userId, switchTarget }) => {
            const peerIdx = findPeer(userId);

            setUserVideoAudio((preList) => {
                let video = preList[peerIdx.userName].video;
                let audio = preList[peerIdx.userName].audio;

                if (switchTarget === 'video') video = !video;
                else audio = !audio;

                return {
                    ...preList,
                    [peerIdx.userName]: { video, audio },
                };
            });
        });
    },[])


    return (
        <>
            <div className="warraperEd">
                <section className="edit-page">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className="edit-page-container">
                            <div className="row">

                                <div className="col-12 col-md-12 p-md-0">
                                    <div className="go-live-box">
                                        <div className="go-live-box-top">
                                            <div className="container-fluid">
                                                <div className="row justify-content-end">
                                                    <div className="col-12 col-md-6">
                                                        <div
                                                            className="go-live-box-top-row d-flex  justify-content-end">
                                                            {/* <div className="go-live-box-top-row-search mr-3">
                                                        <input type="text" className="form-control" placeholder="Add Campaign Name"/>
                                                    </div> */}
                                                            <div className="go-live-box-top-row-button">
                                                                {/*<button onClick={handleExitStudio} className="btn btn btn-link btn-gradient-orange btn-xs-small" type="submit">*/}
                                                                {/*    Exit Studio*/}
                                                                {/*    <span className="icon ml-2">*/}
                                                                {/*        <FontAwesomeIcon icon={faAngleDoubleRight}> </FontAwesomeIcon>*/}
                                                                {/*    </span>*/}
                                                                {/*</button>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="editTabNav d-none">
                                            {/*<StudioNavigation />*/}
                                        </div>

                                        <div className="go-live-box-body">
                                            <div className="go-live-box-options">
                                                <div className="row justify-content-center">
                                                    <GuestCameraSettings addFeed={addFeed} removeFeed={removeFeed}/>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-10 mx-auto text-center">
                                                    <div className='scalevidforGuest'>
                                                        <div className="scale-video-wrap">
                                                            <div className="scale-video preview-bg">
                                                                <video ref={guestPreview}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="go-live-box-footer">
                                            <div className="row justify-content-center">
                                                <div className="col-12">
                                                    <div className="go-live-box-footer-row">
                                                        <ul>
                                                            <GuestSelfUser stream={localStream} localUser={userVideoAudio.localUser} name={hostStream.name} />
                                                            {totalFeeds.map(feed => <GuestTimelineUsers feed={feed}/>)}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Tab.Container>
                </section>
            </div>

            {/*<GuestChat/>*/}
        </>
    );
};

export default GuestStudioDetails;
