const initialState = {
    id: false,
    roomId: false,
    name: '',
    guest: {
        video: true,
        audio: true
    }
}


export default function (state=initialState, action) {
    switch (action.type) {

        case 'ADD_GUEST_STUDIO_DATA':
            return{
                ...state,
                id: action.payload.id,
                roomId: action.payload.room_id
            }

        case 'UPDATE_GUEST_NAME':
            return {
                ...state,
                name: action.payload
            }

        case 'UPDATE_GUEST_ROOM_ID':
            return {
                ...state,
                roomId: action.payload
            }

        case 'UPDATE_GUEST_AUDIO_STATUS':
            return {
                ...state,
                guest: {
                    ...state.guest,
                    audio: action.payload
                }
            }

        case 'UPDATE_GUEST_VIDEO_STATUS':
            return {
                ...state,
                guest: {
                    ...state.guest,
                    video: action.payload
                }
            }

        default:
            return state

    }
}