import React, {useEffect, useState} from 'react';
import {Nav, Tab} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVolumeUp} from "@fortawesome/free-solid-svg-icons";
import MusicUpload from "./MusicUpload";
import MusicLibrary from "./MusicLibrary";
import {useDispatch, useSelector} from "react-redux";
import {updateStudioData} from "../../../actions/studioAction";

const Music = () => {

    const dispatch = useDispatch();
    const musicData = useSelector(state => state.studio.music)

    const [currentUrl, setCurrentUrl] = useState('');
    const [selectedAudio, setSelectedAudio] = useState('');
    const [volume, setVolume] = useState('1');
    const [loop, setLoop] = useState(false)

    const handleVolume = (e) => {
        setVolume(e.target.value);

        dispatch(updateStudioData('music_volume',e.target.value))
    }

    const handleLoop = () => {
        setLoop(!loop);

        dispatch(updateStudioData('music_loop',!loop))
    }

    useEffect(()=>{
        if(musicData.length > 0){
            setLoop(musicData[0].loop);
            setVolume(musicData[0].volume);
            setCurrentUrl(musicData[0].url);
            setSelectedAudio(musicData[0].url);
        }
    },[])

    return (
        <Tab.Pane eventKey="second">

            <h2 className="tabTitle alt">Music</h2>
            <div className="tab-content inTab">
                <Tab.Container id="left-tabs-example2" defaultActiveKey="music-1">
                    <Nav variant="pills" className="">
                        <Nav.Item>
                            <Nav.Link eventKey="music-1">
                                <div className="edit-page-box-tab-box">
                                    <p>Library Audio</p>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="music-2">
                                <div className="edit-page-box-tab-box">
                                    <p>Upload Audio</p>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <div className="content-box content-box--broadcast box-form-control purple-box mt-4">
                        {/* <label>Volume</label> */}
                        <div className="musicCtrl">
                            <div className="musicCtrl-left">
                                <div className="volIcon"><FontAwesomeIcon icon={faVolumeUp}></FontAwesomeIcon></div>
                                <div className="volTrack"><input disabled={musicData.length?false:true} type="range" value={volume} step="0.1" min="0" max="1.0" className="sliderN" onChange={(e)=>handleVolume(e)}/></div>
                            </div>
                            <div className="musicCtrl-right">
                                <div className="box-tools">
                                    <button type="button" className="btn btn-remove btn-xs remove-preview  d-flex  align-items-center">
                                        Loop
                                        <label className="switch ms-3">
                                            <input disabled={musicData.length?false:true} checked={loop} type="checkbox" onChange={()=>handleLoop()} />
                                            <span className="slider round"></span>
                                        </label>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Tab.Pane eventKey="music-1">

                        <MusicLibrary
                            currentUrl={currentUrl}
                            setCurrentUrl={setCurrentUrl}
                            selectedAudio={selectedAudio}
                            setSelectedAudio={setSelectedAudio}
                            volume={volume}
                            loop={loop}
                        />

                    </Tab.Pane>
                    <Tab.Pane eventKey="music-2">
                        <MusicUpload
                            currentUrl={currentUrl}
                            setCurrentUrl={setCurrentUrl}
                            selectedAudio={selectedAudio}
                            setSelectedAudio={setSelectedAudio}
                            volume={volume}
                            loop={loop}
                        />
                    </Tab.Pane>
                </Tab.Container>
            </div>

            {/* <div className="broadcast-content">
                                            <div className="content-box content-box--broadcast box-form-control purple-box mt-4"></div>
                                           <div className="content-box content-box--broadcast box-form-control purple-box mt-4">
                                                    <div className="form-group thumbnail-form">
                                                        <label className="control-label">Upload Music</label>
                                                        <div className="dropzone-wrapper">
                                                            <div className="dropzone-desc">
                                                            <p>
                                                            <span className="icon"><FontAwesomeIcon icon={faCloudUploadAlt}></FontAwesomeIcon></span> Upload Music</p>
                                                            </div>
                                                            <input type="file" name="img_logo" class="dropzone"/>
                                                        </div>
                                                        <div className="preview-zone hidden">
                                                            <div className="box box-solid">
                                                                <div className="box-header with-border">
                                                                    <div className="box-tools pull-right">
                                                                    <button type="button" class="btn btn-remove btn-xs remove-preview  d-flex  align-items-center">
                                                                        Remove
                                                                        <label className="switch ms-3">
                                                                            <input type="checkbox"/>
                                                                            <span className="slider round"></span>
                                                                        </label>
                                                                    </button>
                                                                    </div>
                                                                </div>
                                                                <div className="box-body"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                           </div> */}
        </Tab.Pane>
    );
};

export default Music;
