import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMusic, faPlayCircle, faPauseCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {updateStudioData} from "../../../actions/studioAction";

let audio = false;
const MusicFiles = (props) => {

    const dispatch = useDispatch();

    const [play, setPlay] = useState(false);
    const [loader, setLoader] = useState(false);

    const playAudio = (url) => {

        if (audio !== false) {
            audio.pause();
            setPlay(false);
        }

        setLoader(true);
        audio = new Audio(url);
        audio.play()
            .then(r => setLoader(false)).catch(err => console.log(err));

        props.setCurrentUrl(url);

        setPlay(true)

        audio.onended = function (){
            setPlay(false);
            props.setCurrentUrl('');
        }


    }

    let clearPause;
    const pauseAudio = () => {

        if (audio !== false) {
            clearTimeout(clearPause);
            audio.pause();
            setPlay(false);
            audio = false;
        }
    }

    const selectAudio = (url) => {
        let audioObj = {
            enable: true,
            url: url,
            volume: props.volume,
            loop: props.loop
        }
        props.setSelectedAudio(url);

        dispatch(updateStudioData('music',audioObj))
    }

    const removeAudio = () => {
        props.setSelectedAudio('');

        dispatch(updateStudioData('music',''))
    }


    useEffect(() => {
        return () => {
            if (audio !== false) {
                audio.pause();
            }
        }
    }, [])

    return(
        <>
            {/* <div className="audioSingle">
                <div className="audioSingle-left"><FontAwesomeIcon icon={faMusic}></FontAwesomeIcon></div>
                <div className="audioSingle-right">
                    <span>{props.name}</span>
                </div>
                <div className="audioSingle-play">
                    <span><FontAwesomeIcon icon={faPlayCircle}></FontAwesomeIcon></span>
                    <span>Play</span>
                </div>
            </div> */} 


            <div className="audioSingle" >
                <div className='audioLeft'>
                    <div className="audioSingle-left"><FontAwesomeIcon icon={faMusic} /></div>
                    <div className="audioSingle-right">
                        <span>{props.name}</span>
                    </div>
                </div>
                <div className="audioSingle-control">
                    {
                        play && props.currentUrl === props.url ?
                            <FontAwesomeIcon onClick={()=>pauseAudio()} className="cursor-pointer" icon={loader ? faSpinner : faPauseCircle} />
                        :
                            <FontAwesomeIcon onClick={()=>playAudio(props.url)} className="cursor-pointer" icon={faPlayCircle} />
                    }
                </div>
                {
                    props.selectedAudio === props.url ?
                        <div className="audioSingle-play" style={{opacity: 1, visibility: "visible"}} onClick={()=>removeAudio()}>
                            <span><FontAwesomeIcon icon={faMusic}></FontAwesomeIcon></span>
                            <span>Selected</span>
                        </div>
                    :
                        <div className="audioSingle-play" onClick={()=>selectAudio(props.url)}>
                            <span><FontAwesomeIcon icon={faMusic}></FontAwesomeIcon></span>
                            <span>Select Audio</span>
                        </div>
                }
            </div>
        </>
    )
}

export default React.memo(MusicFiles);