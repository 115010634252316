import React from "react";
import project_1 from "../../images/project1.png";
import red_play from "../../images/red-play.png";
import {Link} from "react-router-dom";
import {baseURL} from "../../global/global";

const VideoCard = ({title, thumbnail, created, id, status, onPopupOpen, schedule, url, memberships}) => {
    return(
        <>
            <div className="col-12  col-md-6 col-xl-3 mb-4 my-video-projects-box">
                <div className="card">
                    <div className="card-box">
                        <img className="card-img-top" src={thumbnail.includes('http')?JSON.parse(thumbnail):project_1}
                             alt="Voice Projects"/>
                        <div className="card-box-overlay">
                            <p style={{marginBottom: '0'}} className="card-box-date">{created}</p>

                            {/* <p style={{letterSpacing: '2px'}} className="card-box-date m-1">
                                {status === "0" ? schedule.schedule === "true" ? <span className="badge badge-warning">Schedule</span> : <span className="badge badge-info">Pending</span> : <span className="badge badge-success">Completed</span>}
                            </p> */}
                            {/*<div className="video-dropdown dropdown">*/}
                            {/*    <button className="btn btn-secondary dropdown-toggle" type="button"*/}
                            {/*            id="dropdownVideoButton" data-bs-toggle="dropdown"*/}
                            {/*            aria-expanded="false">*/}
                            {/*        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>*/}
                            {/*    </button>*/}
                            {/*    <ul className="dropdown-menu" aria-labelledby="dropdownVideoButton">*/}
                            {/*        <li><a className="dropdown-item" href="#">Action</a></li>*/}
                            {/*        <li><a className="dropdown-item" href="#">Another action</a>*/}
                            {/*        </li>*/}
                            {/*        <li><a className="dropdown-item" href="#">Something else*/}
                            {/*            here</a></li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                            <ul className="card-box-edit-more">
                                <li>
                                    <Link to={`/studio?id=${id}`}>
                                        <span><i className="far fa-edit"> </i></span>
                                        <p>Enter Studio</p>
                                    </Link>
                                </li>

                                <li>
                                    <a className="cursor-pointer" onClick={()=>onPopupOpen(id)}>
                                        <span><i className="far fa-trash-alt"> </i></span>
                                        <p>Delete</p>
                                    </a>
                                </li>

                                {
                                    status === "1" ?
                                        <>

                                            <li>
                                                <a href={`${baseURL}download-file?url=${url}`} className="cursor-pointer" >
                                                    <span><i className="fa fa-download"> </i></span>
                                                    <p>Download</p>
                                                </a>
                                            </li>
                                            {
                                                memberships.includes('professional') || memberships.includes('professionallite') ?
                                                    <li>
                                                        <Link to={`/transcribe?id=${id}`} className="cursor-pointer" >
                                                            <span><i className="fa fa-cloud"> </i></span>
                                                            <p>Transcribe</p>
                                                        </Link>
                                                    </li>
                                                : null
                                            }
                                        </>
                                    : null
                                }
                            </ul>
                        </div>
                        {status === "0" ? schedule.schedule === "true" ? <div className="pendingMsg">
                            <span>Broadcasting Scheduled</span>
                        </div> : <div className="pendingMsg bdPending">
                            <span>Broadcasting Pending</span>
                        </div> : <div className="pendingMsg bdCompleted">
                            <span>Broadcasting Completed</span>
                        </div>}

                    </div>
                    <div className="card-body my-video-projects-box-details">
                        <div className="video-play">
                            <img src={red_play } alt="Play Icon"/>
                        </div>
                        <div className="video-title">
                            <p className="card-text">{title}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VideoCard;