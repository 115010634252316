import React, { useEffect, useState } from "react";
import VideoCard from "../project/VideoCard";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../global/global";
import { setAlert } from "../../actions/alert";
import SweetAlert from "react-bootstrap-sweetalert";
import Alert from "../Alert";

const DashboardContent = () => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [projects, setProjects] = useState([]);
    const [deleteId, setDeleteId] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [buttonText, setButtonText] = useState('Delete');
    const [memberships, setMemberships] = useState([]);

    const onPopupOpen = (id) => {
        setDeleteId(id);
        setShowDelete(true);
    }

    const onConfirm = () => {
        deleteCampaign();
    }

    const deleteCampaign = () => {
        setButtonText('Deleting...');
        axios({
            method: "POST",
            url: `${baseURL}delete-broadcast`,
            data: { id: deleteId },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchProjects();
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButtonText('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButtonText('Delete');
        })
    }

    const fetchProjects = () => {
        setLoader(true);

        axios({
            method: 'POST',
            url: `${baseURL}fetch-user-broadcast`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setProjects(res.data.data)
            }

            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    useEffect(() => {
        fetchProjects();
    }, [])

    useEffect(() => {

        if (auth.user && auth.user.membership !== null)
            setMemberships(auth.user.membership.split('__'))

    }, [auth.user.membership])

    return (
        <>
            <Alert />
            <section className="banner-video-project">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="video-project align-items-center d-md-flex d-block text-center justify-content-between">
                                <h5 className="m-0"><span>Broadcasts </span></h5>
                                {/* <Link>View all <span><i className="fas fa-chevron-circle-right"></i></span></Link> */}
                                <div className="view-all uppertext">
                                    <Link to="/broadcasts" type="button" className="gradiant-button-pb view-all">
                                        View All Broadcasts
                                        <span> <i className="fas fa-chevron-circle-right"></i> </span>
                                        {/* <img src={next_icon} alt="Play icon" className="play-icon"/> */}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="my-video-projects">
                            <div className="row gx-4">
                                {
                                    projects.length > 0 ?
                                        projects.slice(0, 4).map(item => {
                                            return (
                                                <VideoCard
                                                    key={item.id}
                                                    id={item.id}
                                                    title={item.title}
                                                    thumbnail={item.thumbnail}
                                                    created={item.created}
                                                    status={item.status}
                                                    url={item.record_url}
                                                    schedule={JSON.parse(item.schedule)}
                                                    onPopupOpen={onPopupOpen}
                                                    memberships={memberships}
                                                />
                                            )
                                        })
                                        : ''
                                }
                            </div>

                            {
                                loader && projects.length === 0 ?
                                    <h4 className="text-center text-white"><i className="fa fa-spinner fa-spin mr-2" /> Fetching Broadcasts</h4>
                                    : ''
                            }

                            {
                                loader === false && projects.length === 0 ?
                                    <h4 className="text-center text-white">You didn't created any broadcasts yet.</h4>
                                    : ''
                            }
                        </div>
                    </div>

                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText={buttonText}
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="light"
                        title="Are you sure?"
                        onConfirm={() => onConfirm()}
                        onCancel={() => setShowDelete(false)}
                        focusCancelBtn
                        show={showDelete}
                    >
                        You want to delete this broadcast
                    </SweetAlert>

                </div>
            </section>
        </>
    )
}

export default DashboardContent;