import React, {useEffect, useState} from "react";
import {baseURL} from "../../global/global";
import axios from "axios";
import {useSelector} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import HoverVideoPlayer from "react-hover-video-player";

const MediaLibrary = () => {

    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState({
        type: 'image',
        message: false
    })
    const [imageCount, setImageCount] = useState(1);
    const [videoCount, setVideoCount] = useState(1);
    const [search, setSearch] = useState({
        keyword: '',
        type: 'image',
        page_number: 1
    })
    const [imagesData, setImagesData] = useState([]);
    const [videosData, setVideosData] = useState([]);

    const handleChangeInput = (e) => {
        setSearch({...search, keyword: e.target.value});

        setImagesData([])
        setImageCount(1)
        setVideosData([])
        setVideoCount(1)
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        searchMedia();
    }

    const searchMedia = () => {
        setLoader(true);

        let page_number;
        if(search.type === 'image')
            page_number = imageCount
        else
            page_number = videoCount

        axios({
            method: "POST",
            url: `${baseURL}load-library-images`,
            data: { keyword: search.keyword, type: search.type, page_number: page_number },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setError({type: search.type, message: false});

                if(search.type === 'image'){
                    let arr = [...imagesData];
                    setImagesData(arr.concat(res.data.data));
                }else{
                    let arr = [...videosData];
                    setVideosData(arr.concat(res.data.data));
                }

            } else {
                setError({type: search.type, message: res.data.message});
            }

            if (search.type === 'image')
                setImageCount(imageCount=> imageCount+1)
            else
                setVideoCount(videoCount=> videoCount+1)

            setLoader(false);
        }).catch(err => {
            setLoader(false);
            setError({type: search.type, message: 'Something went wrong, please try again'});
        })
    }

    useEffect(()=>{
       return () => {
           setImagesData([]);
           setVideosData([]);
       }
    },[])

    return(
        <>
            <div className="upgrade-content text-white">
                <form className="row bg-table justify-content-center align-items-center searchForm" method="post" onSubmit={(e)=>onFormSubmit(e)}>
                    <div className="form-group librarySearch text-center">
                        <input type="text" name="keyword" autoComplete="off" onChange={(e)=>handleChangeInput(e)} />
                        <button className="btn btn-gradient-orange" type="submit">Search</button>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-center text-center searchOpt">
                        <div><input checked={search.type==='image'?true:false} type="radio" name="type" onChange={(e)=>setSearch({...search, type: 'image'})} /> Images {" "}</div>
                        <div><input checked={search.type==='video'?true:false} type="radio" name="type" onChange={(e)=>setSearch({...search, type: 'video'})} /> Videos</div>
                    </div>
                </form>

                {
                    search.type === 'image' ?
                        <div className="row" id="scrollableImage" style={{ overflowX: "hidden", height: "400px" }}>
                            <InfiniteScroll
                                dataLength={imagesData.length} //This is important field to render the next data
                                next={() => searchMedia()}
                                hasMore={true}
                                scrollableTarget="scrollableImage"
                                className="row"
                            >

                                {
                                    imagesData.length > 0 ?
                                        imagesData.map((item, index) => (
                                            <div className="col-lg-4 cursor-pointer" key={index} >
                                                <div className="search-load d-flex justify-content-center align-items-center test3">
                                                    {/*<div className="vid-dur"><span>1:30</span></div>*/}
                                                    <img src={item?.thumbnail} alt="image" />
                                                </div>
                                            </div>
                                        ))
                                        : ''
                                }
                                {
                                    loader ?
                                        <div className="col-md-12">
                                            <h6 className="text-center" style={{fontSize: "30px"}}><i className="fa fa-spinner fa-spin"/></h6>
                                        </div>
                                        : ''
                                }
                                {
                                    error.message && error.type==='image' ?
                                        <div className="col-md-12">
                                            <h6 className="text-center">{error.message}</h6>
                                        </div>
                                        : ''
                                }
                            </InfiniteScroll>
                        </div>
                    : null

                }


                {
                    search.type === 'video' ?
                        <div className="row" id="scrollableVideo" style={{ overflowX: "hidden", height: "400px" }}>
                            <InfiniteScroll
                                dataLength={imagesData.length} //This is important field to render the next data
                                next={() => searchMedia()}
                                hasMore={true}
                                scrollableTarget="scrollableVideo"
                                className="row"
                            >
                                {
                                    videosData.length > 0 ?
                                        videosData.map((item, index) => (
                                            <div className="colmn col-6 cursor-pointer" key={index}>
                                                <div className="search-load d-flex justify-content-center align-items-center testmedia">
                                                    {/*<div className="vid-dur"><span>1:30</span></div>*/}
                                                    <HoverVideoPlayer
                                                        videoSrc={item.url}
                                                        pausedOverlay={
                                                            <img src={item?.thumbnail} alt={`3d video`} width="100%" />
                                                        }
                                                        loadingOverlay={
                                                            <div className="loader-center"><i style={{ color: "#16d489" }} className="fa fa-spinner fa-spin mr-2" /></div>
                                                        }
                                                        className="video"
                                                    />
                                                </div>
                                            </div>
                                        ))
                                        : ''
                                }
                                {
                                    loader ?
                                        <div className="col-md-12">
                                            <h6 className="text-center" style={{fontSize: "30px"}}><i className="fa fa-spinner fa-spin"/></h6>
                                        </div>
                                        : ''
                                }
                                {
                                    error.message ?
                                        <div className="col-md-12">
                                            <h6 className="text-center">{error.message}</h6>
                                        </div>
                                        : ''
                                }

                            </InfiniteScroll>
                        </div>
                    : null
                }

            </div>
        </>
    )
}


export default React.memo(MediaLibrary)