import React, {useEffect, useState} from 'react';
import checkIcon from "../../../images/check.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEyeSlash, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Tab} from "react-bootstrap";
import {SketchPicker} from "react-color";
import axios from "axios";
import {baseURL} from "../../../global/global";
import {updateCustomizeLayers, updateCustomizeRedux, updateStudioData} from "../../../actions/studioAction";
import swal from "sweetalert";
import {useDispatch, useSelector} from "react-redux";
import randomstring from "randomstring";
import {setAlert} from "../../../actions/alert";
import Alert from "../../Alert";

import cancelIcon from "../../../images/cancel2.png";
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons";
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const Customize = ({streamCustomization,setStreamCustomization}) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const studioData = useSelector(state => state.studio);

    const [loader, setLoader] = useState({
        status: false,
        type: 'logo'
    })
    const [showTextColor, setShowTextColor] = useState(false);
    const [customize, setCustomize] = useState({
        color: '#FF3D38',
        text_style: 'default',
        logo: [],
        overlay: [],
        video: [],
        background: []
    })

    const handleTextColor = (e) => {
        setCustomize({...customize, color: e.hex});
        setStreamCustomization({...streamCustomization,color:e.hex})

        dispatch(updateCustomizeLayers('UPDATE_CUSTOMIZE_TEXT_COLOR', e.hex))
    }

    const handleChangeCustomize = (name, value) => {
        setCustomize({...customize, [name]: value})

        dispatch(updateCustomizeLayers('UPDATE_CUSTOMIZE_TEXT_STYLE', value))
    }

    const handleAddImages = (url, type, thumbnail=false) => {
        let obj = {enable: false, url: url, id: randomstring.generate({length: 10, charset: 'numeric'})}
        if(thumbnail !== false){
            obj.thumbnail = thumbnail
        }
        dispatch({type: `UPDATE_${type.toUpperCase()}`, payload: obj})
    }

    const handleFileChange = (e, type) => {

        if (e.target.files[0] !== undefined) {

            let allowedSize = 100 * 1024 * 1024;
            let allowedExt = ['image/png','image/jpg','image/jpeg','image/JPEG','video/mp4'];
            if(type === 'video'){
                allowedSize = 400 * 1024 * 1204;
                allowedExt = ['video/mp4'];
            }

            if (allowedExt.includes(e.target.files[0].type)) {

                if (e.target.files[0].size < allowedSize) {
                    setLoader({...loader, type: type, status: true});

                    const formData = new FormData();
                    formData.append('broadcast_id', studioData.id);
                    formData.append('file', e.target.files[0])
                    formData.append('type', type)

                    const options = {
                        headers: {
                            'Authorization': auth.token
                        }
                    }

                    axios.post(`${baseURL}user-upload-media`, formData, options).then(res => {
                        if (res.data.status === true) {

                            if(type === 'video') {
                                handleAddImages(res.data.path, type, res.data.thumbnial)
                            }else{
                                handleAddImages(res.data.path, type)
                            }

                        }else{
                            dispatch(setAlert(res.data.message, 'danger'))
                        }
                        setLoader({...loader, type: type, status: false});

                    })

                } else {
                    swal("Oops!", "Max allowed size for video 100MB");
                }

            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }


    const handleClick = (type,url,id,status) => {
        const cust = {...streamCustomization};
        cust[type] = {
            enable:status,
            src:url,
            id:'',
            stream:false
        }
        const localCustomize = {...customize}
        let newArr = localCustomize[type].map(item=>(
            item.id === id ? {...item, enable: status} : {...item, enable: false}
        ))

        dispatch({type: `ADD_STUDIO_${type.toUpperCase()}`, payload: newArr});
        setStreamCustomization(cust)


    }

    useEffect(()=>{
        setCustomize(studioData.customization)
    },[studioData.customization.logo,studioData.customization.overlay,studioData.customization.background,studioData.customization.video])

    return (
        <Tab.Pane eventKey="four">
            <Alert/>
            <h2 className="tabTitle alt">Customization</h2>
            <form action="" method="POST">
                <div className="content-box content-box--brand purple-box box-form-control text-white mb-4 customization-color-picker">
                    <div className="row">
                        <div className="col-12">
                            <h6>Stream Color
                                <span className="icon skyblue" title="Give a color to your stream">
                                    <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                                </span>
                            </h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="color-picker-box  align-items-center d-flex">
                                <div onClick={(e) => setShowTextColor(true)} style={{backgroundColor: `${customize.color}`}}  className="color-picker white cursor-pointer"> </div>
                                <p className="color-text ms-2 text-white">{customize.color}</p>
                                {showTextColor ? <div>
                                    <div className="swatch-cover" onClick={(e) => setShowTextColor(false)}/>
                                    <SketchPicker disableAlpha={true} color={customize.color} onChange={(e) => handleTextColor(e)}/>
                                </div> : null}
                            </div>
                            {/* <p class="small pt-2">HEX</p> */}
                        </div>

                    </div>

                </div>


                <div className="content-box overlay-row content-box--brand purple-box box-form-control text-white mb-4 customization-color-picker">
                    <div>
                        <div className="row">
                            <div className="col-12">
                                <h6>Text Style
                                    <span className="icon skyblue" title="Select a style to show texts in your broadcast">
                                        <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                                    </span>
                                </h6>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <ul>
                                    <li onClick={()=>handleChangeCustomize('text_style','default')}>
                                        <div className={`overlay-box forText ${customize.text_style==='default'?'active':''}`}>
                                            <span className="textDefault" style={{borderColor: `${customize.color}`}}>Default</span>
                                        </div>
                                    </li>
                                    <li onClick={()=>handleChangeCustomize('text_style','news')}>
                                        <div className={`overlay-box forText ${customize.text_style==='news'?'active':''}`}>
                                            <span className="textNews" style={{backgroundColor: `${customize.color}`}}>News</span>
                                        </div>
                                    </li>
                                    <li onClick={()=>handleChangeCustomize('text_style','rounded')}>
                                        <div className={`overlay-box forText ${customize.text_style==='rounded'?'active':''}`}>
                                            <span className="textRounded" style={{backgroundColor: `${customize.color}`, borderRadius: '20px'}}>Rounded</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="content-box logo-row content-box--brand purple-box box-form-control text-white mb-4 customization-color-picker">
                    <div className="row">
                        <div className="col-12">
                            <h6>Logo {" "}
                                <small style={{fontSize: '12px'}}>(100×100 Px)</small>
                                <span className="icon skyblue" title="Add a Logo to your broadcast. Recommended size 100×100 Px">
                                    <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                                </span>
                            </h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ul>
                                {
                                    customize.logo.length > 0 ?
                                        customize.logo.map((item,index)=>(
                                            <li key={index} >
                                                <div className={`logo-box ${!item.enable ? 'circle-img-wrap active' : ''}`}>
                                                    <div className="logo-box-img-logo" >
                                                        <img src={item.url} alt="user img"/>
                                                    </div>
                                                    {
                                                        !item.enable ?
                                                            <div className="circle-img-select" >
                                                                <img src={checkIcon} alt="user img" onClick={() => handleClick('logo',item.url,item.id,true)}/>
                                                                </div>
                                                        : <div className="circle-img-select act" onClick={() => handleClick('logo',item.url,item.id,false)}>
                                                                <img src={cancelIcon} alt=""  />
                                                            </div>
                                                    }
                                                </div>
                                            </li>
                                        ))
                                    : ''
                                }
                                <li>
                                    <div className="logo-box">
                                        <div className="logo-box-add-logo addIcon">
                                            <span>
                                                {
                                                    loader.type==='logo' && loader.status ? <i className="fa fa-spinner fa-spin"/> : <FontAwesomeIcon icon={faPlus} />
                                                }
                                                <input onChange={(e)=>handleFileChange(e, 'logo')} className="hidden-input" type="file"/>
                                            </span>
                                        </div>
                                    </div>


                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="content-box overlay-row content-box--brand purple-box box-form-control text-white mb-4 customization-color-picker">
                    <div>

                        <div className="row">
                            <div className="col-12">
                                <h6>Overlay {" "}
                                    <small>(940×530 Px)</small>
                                    <span className="icon skyblue" title="Add a Overlay to your broadcast. Overlay should be transparent. Recommended size - 940×530 Px">
                                        <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                                    </span>
                                    {/* <a className='downBtn' href="https://reelapps.s3.us-west-2.amazonaws.com/StreamReel/render.zip">Download <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon></a> */}
                                </h6>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <ul>
                                    {
                                        customize.overlay.length > 0 ?
                                            customize.overlay.map((item,index)=>(
                                                <li key={index} >
                                                    <div className={`logo-box ${!item.enable ? 'circle-img-wrap active' : ''}`}>
                                                        <div className="logo-box-img-logo">
                                                            <img src={item.url} alt="user img"/>
                                                        </div>
                                                        {
                                                            !item.enable ?
                                                                <div className="circle-img-select" >
                                                                    <img src={checkIcon} alt="user img" onClick={() => handleClick('overlay',item.url,item.id,true)}/>
                                                                </div>
                                                                : <div className="circle-img-select act" onClick={() => handleClick('overlay',item.url,item.id,false)}>
                                                                    <img src={cancelIcon} alt=""  />
                                                                </div>
                                                        }
                                                    </div>
                                                </li>
                                            ))
                                            : ''
                                    }
                                    <li>
                                        <div className="logo-box">
                                            <div className="overlay-box-add-logo addIcon">
                                                <span>
                                                    {
                                                        loader.type==='overlay' && loader.status ? <i className="fa fa-spinner fa-spin"/> : <FontAwesomeIcon icon={faPlus} />
                                                    }
                                                    <input onChange={(e)=>handleFileChange(e, 'overlay')} className="hidden-input" type="file"/>
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="content-box background-row content-box--brand purple-box box-form-control text-white mb-4 customization-color-picker">
                    <div className="row">
                        <div className="col-12">
                            <h6>Video
                                <span className="icon skyblue" title="Add a video to be player on your broadcast. Recommended size - (1280x720)">
                                    <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                                </span>
                            </h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ul>
                                {
                                    customize.video.length > 0 ?
                                        customize.video.map((item,index)=>(
                                            <li key={index} >
                                                <div className={`logo-box ${!item.enable ? 'circle-img-wrap active' : ''}`}>
                                                    <div className="logo-box-img-logo">
                                                        <img src={item?.thumbnail} alt="user img"/>
                                                    </div>

                                                    {
                                                        !item.enable ?
                                                            <div className="circle-img-select" >
                                                                <img src={checkIcon} alt="user img" onClick={() => handleClick('video',item.url,item.id,true)}/>
                                                            </div>
                                                            : <div className="circle-img-select act" onClick={() => handleClick('video',item.url,item.id,false)}>
                                                                <img src={cancelIcon} alt=""  />
                                                            </div>
                                                    }

                                                </div>
                                            </li>
                                        ))
                                        : ''
                                }
                                <li>
                                    <div className="logo-box">
                                        <div className="background-box-add-logo addIcon">
                                            <span>
                                                {
                                                    loader.type==='video' && loader.status ? <i className="fa fa-spinner fa-spin"/> : <FontAwesomeIcon icon={faPlus} />
                                                }
                                                <input onChange={(e)=>handleFileChange(e, 'video')} className="hidden-input" type="file"/>
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="content-box background-row content-box--brand purple-box box-form-control text-white customization-color-picker">
                    <div className="row">
                        <div className="col-12">
                            <h6>Background {" "}
                                <small>(940×530 Px)</small>
                                <span className="icon skyblue" title="Give a background to your broadcast 940×530 Px">
                                    <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                                </span>
                            </h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ul>
                                {
                                    customize.background.length > 0 ?
                                        customize.background.map((item,index)=>(
                                            <li key={index} >
                                                <div className={`logo-box ${!item.enable ? 'circle-img-wrap active' : ''}`}>
                                                    <div className="logo-box-img-logo">
                                                        <img src={item.url} alt="user img" onClick={() => handleClick('background',item.url,item.id,true)}/>
                                                    </div>
                                                    {
                                                        !item.enable ?
                                                            <div className="circle-img-select" >
                                                                <img src={checkIcon} alt="user img" onClick={() => handleClick('background',item.url,item.id,true)}/>
                                                            </div>
                                                            : <div className="circle-img-select act" onClick={() => handleClick('background',item.url,item.id,false)}>
                                                                <img src={cancelIcon} alt="user img" />
                                                            </div>
                                                    }
                                                </div>
                                            </li>
                                        ))
                                        : ''
                                }
                                <li>
                                    <div className="logo-box">
                                        <div className="background-box-add-logo addIcon">
                                            <span>
                                                {
                                                    loader.type==='background' && loader.status ? <i className="fa fa-spinner fa-spin"/> : <FontAwesomeIcon icon={faPlus} />
                                                }
                                                <input onChange={(e)=>handleFileChange(e, 'background')} className="hidden-input" type="file"/>
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </form>
        </Tab.Pane>
    );
};

export default Customize;
