import React, {useEffect, useRef} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrophone, faUser, faVideo} from "@fortawesome/free-solid-svg-icons";
const GuestTimelineUsers = ({feed}) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if(feed.stream !== false){
            videoRef.current.srcObject = feed.stream;
        }

    },[feed])


    return (
        <li className="go-live-box-footer-group">
            <div className="go-live-box-footer-group-box">
                {/*<div*/}
                {/*    className="go-live-box-footer-group-box-options d-flex justify-content-between">*/}
                {/*    <button type="button" className="btn btn-light">*/}
                {/*        <FontAwesomeIcon icon={faVideo}> </FontAwesomeIcon>*/}
                {/*    </button>*/}
                {/*    <button type="button" className="btn btn-light">*/}
                {/*        <FontAwesomeIcon  icon={faMicrophone}> </FontAwesomeIcon>*/}
                {/*    </button>*/}
                {/*</div>*/}
                <div className="go-live-box-footer-group-box-img">
                    {/*<img src={liveVideo} alt="live video" className="img-fluid"/>*/}
                    <video ref={videoRef} style={{width:'160px'}} autoPlay playsInline muted/>
                </div>
                {feed.name !== false ? <div className="go-live-box-footer-group-box-name d-flex">
                    <span className="icon">
                        <FontAwesomeIcon icon={faVideo}> </FontAwesomeIcon>
                    </span>
                    <p>{feed.name}</p>
                </div>: null}

            </div>


        </li>
    );
};

export default GuestTimelineUsers;
