import {combineReducers} from "redux";
import authReducer from "./authReducer";
import alertReducer from "./alertReducer";
import supportReducer from "./supportReducer";
import rebrandReducer from "./rebrandReducer";
import socialReducer from "./socialReducer";
import studioReducer from "./studioReducer";
import guestStudioReducer from "./guestStudioReducer";

const rootReducers = combineReducers({
    alert: alertReducer,
    auth: authReducer,
    support: supportReducer,
    rebrand: rebrandReducer,
    social: socialReducer,
    studio: studioReducer,
    guestStudio: guestStudioReducer
})

// const rootReducers = (state, action) => {
//     if (action.type === 'LOGOUT') {
//         localStorage.clear();
//         state = undefined
//     }
//     return appReducer(state, action)
// }

export default rootReducers;