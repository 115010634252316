import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import IntegrationHead from "./IntegrationHead";
import ConnectFacebook from "./ConnectFacebook";
import ConnectYoutube from "./ConnectYoutube";
import ConnectReelapps from "./ConnectReelapps";
import twitchIcon from "../../images/TWITCH-Default.png";
import streamIcon from "../../images/STREAMREEL-Default.png";
import {useSelector} from "react-redux";

const Integration = () => {

    const socialData = useSelector(state => state.social);
    const brandName = useSelector(state => state.rebrand.data);

    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'StreamReel'} | Integration </title>
            </Helmet>
            <Navbar/>

            <div className="warraper">
                <IntegrationHead data={socialData} />

                <section className="my-connection">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="text-white">My Connection</h4>
                            </div>
                        </div>

                        <ConnectFacebook data={socialData.facebook}/>
                        <ConnectYoutube data={socialData.youtube} />
                        <ConnectReelapps icon={twitchIcon} name="Twitch" type="twitch" data={socialData.twitch} />
                        <ConnectReelapps icon={streamIcon} name="LinkedIn & RTMP" type="rtmp" data={socialData.customRtmp} />
                        {/*<ConnectReelapps icon={trimIcon} name="TrimReel" type="trimreel" data={socialData.trimreel} />*/}
                        {/*<ConnectReelapps icon={liveIcon} name="LiveReel" type="livereel" data={socialData.livereel} />*/}
                        {/*<ConnectReelapps icon={sonorityIcon} name="Sonority" type="sonority" data={socialData.sonority} />*/}

                    </div>
                </section>

                {/* <Webinar/> */}
            </div>

            <Footer/>
        </>
    )
}

export default Integration;