import React, {useEffect, useState} from 'react'
import webimg from '../../images/webimg.png'
import play_icon from "../../images/play-icon.png"
import axios from "axios";


function Webinar() {

  const [webinar, setWebinar] = useState({
    title: '',
    description: '',
    btn_link: '',
    btn_text: '',
    status: '1'
  });

  useEffect(()=>{

    axios({
      method: 'POST',
      url: 'https://adminserver.reelapps.io/api/webinar',
      data: {id: 41},
      headers: {'Content-Type' : 'application/json'}
    }).then(res=>{
      if(res.data.status === true){
        setWebinar(res.data.data[0]);
      }
    }).catch(err=>{

    });

  },[]);

    return (
        <>
          <section className="webinar-sec">
  <div className="container">
    <div className="row">
      {
        webinar.btn_link !== '' ?
            <div className="col-sm-12 col-lg-6 d-flex align-items-center ">
              <div className="webiner-content">
                <span>STREAMREEL LESSON #1</span>
                <h3 className="mt-3">
                  {webinar.title}
                </h3>
                <p className="mt-3">
                  {webinar.description}
                </p>
                {/* <a href="https://abhineos.com/training" target="_blanck">
            <button type="button" className="mt-4 medium-btn">
              Sign Up Now
            </button>
          </a> */}
                <div className='banner-content'>
                  <div className='watch-demo-btn mt-5'>
                    <a href={webinar.btn_link} target="_blank" style={{display: 'inline-block'}} className="gradiant-button-pb watch-btn mt-4">
                      <span><i className="fas fa-play"></i></span> {webinar.btn_text}
                    </a>
                  </div>
                </div>
              </div>
            </div>
        : ''
      }
      <div className="col-sm-12 col-lg-6">
        <div className="webinar-img mt-3 mt-md-0">
          <img src={webimg} alt className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>

<section className="webinar-sec afterLaunch d-none">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-md-12 d-flex align-items-center ">
        <div className="webiner-content">
          <h3 className="mt-3"><span>Got questions?</span> <br/> We’re here for you.</h3>
          <p className="mt-3">
          If you need any help or support using StreamReel, our easy-to-follow tutorials are available for you 24/7. These step-by-step video tutorials will show you the ropes in setting up StreamReel to give you maximum engagement, views, and profits. 
          </p>
          <div className='banner-content'>
            <div className='watch-demo-btn mt-5'>
          <a href="/help-and-support" style={{display: 'inline-block'}} className="gradiant-button-pb watch-btn mt-4">
              <span><i className='fas fa-play'></i></span> Get Started Here
					</a>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  
        </>
    )
}

export default Webinar
