import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import TrainingTitles from "./TrainingTitles";
import TrainingContents from "./TrainingContents";
import Footer from "../Footer";
import {useSelector} from "react-redux";
import queryString from "query-string";

const Training = ({location}) => {
    const brandName = useSelector(state => state.rebrand.data);

    const supportData = useSelector(state => state.support.articles);
    const articleId = queryString.parse(location.search).id;

    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'StreamReel'} | Help & Support </title>
            </Helmet>
            <Navbar/>

            <section className="traning-sec">
                <div className="container">
                    <div className="row">

                    <div className="col-md-12 mb-4" style={{margin: "30px 0 0 0"}} />

                        <TrainingTitles location={location} id={articleId} supportData={supportData} name={false} />
                        <TrainingContents location={location} />

                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Training;