import React, {useEffect, useState} from "react";
import player_icon from "../../images/player-icon.png";
import next_icon from "../../images/next.png";
import image3 from "../../images/login-bg.jpg"
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {addScrapText} from "../../actions/videoAction";
import closeModalx from "../../images/closeModal.svg"

const DashboardHead = () => {

    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);

    const [rebrand, setRebrand] = useState(rebrandData);
    const [frameUrl, setFrameUrl] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [bgVideo, setBgVideo] = useState(false);
    const [demoVideo, setDemoVideo] = useState(false);


    const addFrameUrl = () => {
        setShowModal(true);
        setFrameUrl(demoVideo);
    }

    const closePopUp = () => {
        setFrameUrl('');
        setShowModal(false);
    }

    useEffect(()=>{

        axios({
            method: 'POST',
            url: 'https://adminserver.reelapps.io/api/walkthrough',
            data: {id: 41},
            headers: {'Content-Type' : 'application/json'}
        }).then(res=>{
            if(res.data.status === true){
                setBgVideo(res.data.data[0].bg_video);
                setDemoVideo(res.data.data[0].demo_video);
            }
        }).catch(err=>{

        });

    },[]);

    useEffect(()=>{
        setRebrand(rebrandData);
    },[rebrandData])

    return(
        <>
            <section id="banner" className="bashboard-banner" 
            // style={{backgroundImage: "url("+image3+")"}}
            >
                <div id="bg-video">
                {/* <iframe src={`${bgVideo}?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0`} frameborder="0" title="course"></iframe> */}
                <video
                    src="https://reeelapps-app.s3.us-west-2.amazonaws.com/streamreel_dashboard_bg_video+(1080p).mp4"
                    autoPlay
                    muted
                    style={{ width: "100%" }}
                    loop
                />
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-7">
                            <div className="banner-content text-white">
                                <h4>WELCOME TO </h4>
                                {
                                    rebrand.data === false ? <h1><span>Stream</span>Reel</h1> : <h1>{rebrand.data.name}</h1>
                                }
                                <p>Broadcast professional-looking livestreams and teleconferences effortlessly. Watch how StreamReel delivers the best of both worlds by giving you full control and freedom in customizing and streaming your videos.</p>
                                <div className="watch-demo-btn mt-5">
                                    <button onClick={(e)=>addFrameUrl()} type="button" data-toggle="modal" data-target="#myModal" className="gradiant-button-pb watch-btn mt-4"><span>
                                        <i className="fas fa-play"></i></span> Watch Demo Video
                                    </button>
                                    
                                </div>

                                <div className={`commonModal modal ${showModal ? 'show-modal' : ''} `}>
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            
                                                {/* <h5 className="modal-title"></h5> */}
                                                <button onClick={(e)=>closePopUp('')} type="button" className="close" data-dismiss="modal"
                                                        aria-label="Close">
                                                    <img src={closeModalx} />
                                                </button>
                                            
                                            <div className="modal-body">
                                                <div className="embed-responsive embed-responsive-16by9">
                                                    <iframe id="frameSrc" className="embed-responsive-item" src={frameUrl} allowFullScreen=""></iframe>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4 offset-lg-1 p-lg-0">

                            <div className="more-about-services">
                                {auth.user.is_client_account === '1' ? null : <div className="more-about-services-list mb-3">
                                    <Link to={`/broadcast`} className="btn btn-gradient-white btn-st">
                                        <div className="serv-box">
                                            <div className="serv-icon">
                                                <img src={player_icon} alt="player icon"/>
                                            </div>
                                            <p>Broadcast Now</p>
                                        </div>
                                        <div className="next">
                                            <img src={next_icon} alt="Right arrow" className="right-arrow"/>
                                        </div>
                                    </Link>
                                </div>}

                                {/* <div className="more-about-services-list mb-3">
                                    <button type="button" className="btn btn-gradient-white btn-st">
                                        <div className="serv-box">
                                            <div className="serv-icon">
                                                <img src={player_icon} alt="player icon"/>
                                            </div>
                                            <p>Lorem Ipsum</p>
                                        </div>
                                        <div className="next">
                                            <img src={next_icon} alt="Right arrow" className="right-arrow"/>
                                        </div>
                                    </button>
                                </div> */}
                                {/* <div className="more-about-services-list mb-3">
                                    <button type="button" className="btn btn-gradient-white btn-st">
                                        <div className="serv-box">
                                            <div className="serv-icon">
                                                <img src={player_icon} alt="player icon"/>
                                            </div>
                                            <p>Lorem Ipsum</p>
                                        </div>
                                        <div className="next">
                                            <img src={next_icon} alt="Right arrow" className="right-arrow"/>
                                        </div>
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default DashboardHead;