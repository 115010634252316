import React from 'react'
import customer_service from "../../images/customer-service.png";

function CustomerSupport() {
    return (
        <div>
            <section className="customer-sec">
                 <div className="container">
                      <div className="row d-flex align-items-center"> 
                      <div className="col-12 col-md-6">
                            <img src={customer_service} alt="Customer Support image" class="img-fluid"/>
                        </div>
                        <div className="col-12 col-md-6">
                               <div className="customer-content">
                                    <h6>CUSTOMER SUPPORT</h6>
                                    <h5>Can't find your answer?</h5>
                                    <p>We're here to help! From general communication to specific StreamReel
                                        platform questions, please reach out so we can assist
                                    </p>
                                    <a  className="gradiant-button-pb view-all"  target="_blank" href="https://support.vineasx.com/">CONTACT OUR HELPDESK</a>
                               </div>
                        </div>
                      
                      </div>
                 </div>
            </section>
        </div>
    )
}

export default CustomerSupport
