import React, {useEffect, useRef, useState} from 'react';
import {VideoStreamMerger} from "video-stream-merger";
import { memo } from "react";
let merger = false;
const Preview_6 = ({totalFeeds, setFinalStream}) => {
    const screenRef = useRef(null);
    const [feedIndex, setFeedIndex] = useState(-1)

    useEffect(() => {
        totalFeeds.forEach((feed,index) => {
            if(feed.status){
                setFeedIndex(index);
            }else{
                setFeedIndex(-1);
            }
        })
    },[JSON.stringify(totalFeeds)])

    useEffect(() => {
        if(feedIndex > -1){
            const feed = totalFeeds[feedIndex];
            if(merger !== false){
                merger.destroy();
            }
            merger = new VideoStreamMerger({width:1280,height:720})
            if(feed.feedType === 'screen'){

                screenRef.current.srcObject = feed.stream
                merger.addStream(feed.stream, {
                    x: 0,
                    y: 0,
                    width: 1280,
                    height: 720
                })
            }
            if(feed.feedType === 'video'){
                screenRef.current.src = feed.stream.url
                merger.addMediaElement('videoElement',screenRef.current, {
                    x: 0,
                    y: 0,
                    width: 1280,
                    height: 720
                })
            }
            merger.start();
            setFinalStream(merger.result)
        }

    },[feedIndex])

    return (
        <>
            {feedIndex > -1 ? <video ref={screenRef} autoPlay playsInline /> : null}
        </>
    );
};

export default memo(Preview_6);
