import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudUploadAlt} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../../../global/global";
import {setAlert} from "../../../actions/alert";
import swal from "sweetalert";
import MusicFiles from "./MusicFiles";

let allowedExt = ['audio/mpeg'];

const MusicUpload = ({selectedAudio, currentUrl, setCurrentUrl, setSelectedAudio, loop, volume}) => {

    const auth = useSelector(state => state.auth);
    const studioData = useSelector(state => state.studio);
    const dispatch = useDispatch();

    const [broadcastId, setBroadcastId] = useState(false);
    const [musicFiles, setMusicFiles] = useState([]);
    const [percent, setPercent] = useState(0);
    const [loader, setLoader] = useState(false);

    const handleUploadFile = (e) => {
        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {

                let allowedSize = 5000000;

                if (e.target.files[0].size < allowedSize) {
                    setLoader(true);

                    const formData = new FormData();
                    formData.append('broadcast_id', broadcastId);
                    formData.append('type', 'music');
                    formData.append('file', e.target.files[0])

                    const options = {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            let percent = Math.floor((loaded * 100) / total);

                            setPercent(percent);
                        },
                        headers: {
                            'Authorization': auth.token
                        }
                    }

                    axios.post(`${baseURL}user-upload-media`, formData, options).then(res => {
                        if (res.data.status === true) {

                            fetchUploadedMedia();
                            dispatch(setAlert(res.data.message, 'success'));

                        } else {
                            dispatch(setAlert(res.data.message, 'danger'));
                        }
                        setLoader(false);
                        setPercent(0);
                    })

                } else {
                    swal("Oops!", "Max allowed size for MP3 5MB");
                }

            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }


    const fetchUploadedMedia = () => {
        axios({
            method: 'POST',
            url: `${baseURL}user-media`,
            data: {broadcast_id: studioData.id, type: 'mp3'},
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setMusicFiles(res.data.data);
            }
        }).catch(err=>{

        })
    }

    useEffect(()=>{
       setBroadcastId(studioData.id);

       fetchUploadedMedia();
    },[])

    return(
        <>
            <div className="content-box content-box--broadcast box-form-control purple-box mt-4">
                <div className="form-group thumbnail-form">
                    <label className="control-label">Upload Audio</label>
                    <div className="dropzone-wrapper">
                        <div className="dropzone-desc">
                            <p>
                                <span className="icon"><FontAwesomeIcon icon={faCloudUploadAlt}></FontAwesomeIcon></span> Upload Audio</p>
                        </div>
                        <input disabled={loader} type="file" name="img_logo" className="dropzone" onChange={(e)=>handleUploadFile(e)}/>
                    </div>
                    {
                        loader ?
                            <div className="progress mt-4">
                                <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#FF3D38" }} aria-valuenow={`${percent}`}
                                     aria-valuemin="0" aria-valuemax="100">{percent>5?percent+'%':''}
                                </div>
                            </div>
                        : ''
                    }
                </div>

            </div>

            {
                musicFiles.length > 0 ?
                    <div className="content-box content-box--broadcast box-form-control purple-box mt-4">
                        {
                            musicFiles.map((item, index)=>(
                                <MusicFiles
                                    name={item.name}
                                    url={item.file}
                                    currentUrl={currentUrl}
                                    setCurrentUrl={setCurrentUrl}
                                    selectedAudio={selectedAudio}
                                    setSelectedAudio={setSelectedAudio}
                                    loop={loop}
                                    volume={volume}
                                    key={index}
                                />
                            ))
                        }
                    </div>
                : ''
            }
        </>
    )
}

export default React.memo(MusicUpload);