import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import queryString from "query-string";
import axios from "axios";
import {baseURL} from "../../../global/global";
import project_1 from "../../../images/project1.png";
import {useHistory} from "react-router-dom";
import randomstring from "randomstring";
import {setAlert} from "../../../actions/alert";
import Alert from "../../Alert";

let transcribeInterval = false;
const Transcribe = ({location}) => {
    const auth = useSelector(state => state.auth);
    const brandName = useSelector(state => state.rebrand.data);

    const dispatch = useDispatch();
    const history = useHistory();
    const id = queryString.parse(location.search).id;

    const [loader, setLoader] = useState({
        type: '',
        status: false
    })
    const [playStatus, setPlayStatus] = useState(false);
    const [project, setProject] = useState(false);
    const [transcribeLang, setTranscribeLang] = useState([]);
    const [translateLang, setTranslateLang] = useState([]);
    const [jobId, setJobId] = useState(false);
    const [transcribe, setTranscribe] = useState({
        broadcast_id: id,
        language: '',
        text: ''
    })
    const [translate, setTranslate] = useState({
        id: id,
        lang_code: '',
        text: ''
    })

    const handleTranscribeChange = (e) => {
        setTranscribe({...transcribe, [e.target.name]: e.target.value})
    }

    const handleTranslateChange = (e) => {
        setTranslate({...translate, [e.target.name]: e.target.value})
    }

    const handlePlayPauseVideo = (status) => {
        setPlayStatus(status);

        const videoElement = document.getElementById('videoTrans');
        const playPauseButton = document.querySelector('button');


        if(status)
            videoElement.play();
        else
            videoElement.pause();

        videoElement.addEventListener('ended', () => {
            playPauseButton.classList.remove('playing');
            setPlayStatus(false);
        });

    }

    const transcribeText = () => {
        setLoader({type: 'transcribe', status: true});

        axios({
            method: 'POST',
            url: `${baseURL}transcribe`,
            data: transcribe,
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setJobId(res.data.data);
            }else{
                dispatch(setAlert(res.data.message, 'danger'))
            }

            setLoader({type: '', status: false});
        }).catch(err=>{
            setLoader({type: '', status: false});
            dispatch(setAlert('Something went wrong, Please try again', 'danger'))
        })
    }

    const checkTranscribeStatus = (job_id) => {
        setLoader({type: 'transcribe', status: true});

        axios({
            method: 'POST',
            url: `${baseURL}transcribe-check`,
            data: {job_name: job_id, broadcast_id: id},
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                if(res.data.message === 'Transcribe Complete'){
                    clearInterval(transcribeInterval);

                    setTranslate({...translate, text: res.data.data})
                    setTranscribe({...transcribe, text: res.data.data})
                    setLoader({type: '', status: false});
                }
            }
        }).catch(err=>{
            setLoader({type: '', status: false});
        })
    }

    const translateText = () => {
        setLoader({type: 'translate', status: true});

        axios({
            method: 'POST',
            url: `${baseURL}translate`,
            data: translate,
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setTranslate({...translate, text: res.data.data.text})
            }else{
                dispatch(setAlert(res.data.message, 'danger'))
            }

            setLoader({type: '', status: false});
        }).catch(err=>{
            setLoader({type: '', status: false});
            dispatch(setAlert('Something went wrong, Please try again', 'danger'))
        })
    }

    const fetchTranscribeLang = () => {
        axios({
            method: 'POST',
            url: `${baseURL}fetch-transcribe-languages`,
            data: transcribe,
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setTranscribeLang(res.data.data.languages)
            }
        }).catch(err=>{

        })
    }

    const fetchTranslateLang = () => {
        axios({
            method: 'POST',
            url: `${baseURL}fetch-translate-languages`,
            data: transcribe,
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setTranslateLang(res.data.data.languages)
            }
        }).catch(err=>{

        })
    }

    const downloadTxtFile = (type, selector) => {
        const element = document.createElement("a");
        const file = new Blob([document.getElementById(selector).value], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = `${type}_${randomstring.generate({length: 10, charset: 'alphabetic'})}.txt`;
        document.body.appendChild(element);
        element.click();
    }

    useEffect(()=>{
        if(id){
            axios({
                method: 'POST',
                url: `${baseURL}fetch-broadcast`,
                data: {id},
                headers: {'Content-Type': 'application/json','Authorization': auth.token}
            }).then(res=>{
                if(res.data.status === true){
                    setProject(res.data.data);
                    setTranscribe({...transcribe, text: res.data.data.original_transcript})
                    setTranslate({...translate, text: res.data.data.original_transcript})

                    if(res.data.data.transcribe_status === "2"){
                        setJobId(res.data.data.job_name)
                    }
                }
            }).catch(err=>{

            })
        }else{
            history.push('/dashboard');
        }
    },[])

    useEffect(()=>{
        fetchTranscribeLang();
        fetchTranslateLang();
    },[])

    useEffect(()=>{
        if(jobId){
            transcribeInterval = setInterval(()=>{
                checkTranscribeStatus(jobId);
            }, 10000)
        }
    },[jobId])

    return(
        <>
            <Alert/>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'StreamReel'} | Dashboard </title>
            </Helmet>

            <Navbar/>
            <section className="profile-sec">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="trans-wrapper">
                                <div className="trans-thumb">
                                    {
                                        project ?
                                            <>
                                                <video id="videoTrans">
                                                    <source src={project.record_url} type="video/mp4" />
                                                </video>
                                                <div className="videoTrans-button">
                                                <button className="video-control" onClick={()=>handlePlayPauseVideo(!playStatus)}>
                                                    {
                                                        playStatus ?
                                                            <span className="video-control-play">
                                                                <span className="video-control-symbol" aria-hidden="true"><i className="fa fa-pause" /></span>
                                                            </span>
                                                            :
                                                            <span className="video-control-play">
                                                                <span className="video-control-symbol" aria-hidden="true"><i className="fa fa-play" /></span>
                                                            </span>
                                                    }
                                                </button>
                                                </div>
                                            </>
                                        :
                                            <img src={project_1 } alt="thumbnail" width="100%" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Start Transcribe */}
                    <div className="personal-details-box">
                    <div className="row">
                        <div className="col-md-10 mx-auto mt-5">
                            <div className="form-box">
                            <div className="form-group forSelect">
                                <select className="form-control" name="language" onChange={(e)=>handleTranscribeChange(e)}>
                                    <option selected disabled>-- Select Languages--</option>
                                    {
                                        transcribeLang.length > 0 ?
                                            transcribeLang.map((item,index)=>(
                                                <option key={index} value={item.code}>{item.name}</option>
                                            ))
                                            : null
                                    }
                                </select>
                            </div>
                            <button type="button" onClick={transcribeText} disabled={loader.status} className="btn gradiant-button-pb border-15 btn-submit">
                                { loader.status && loader.type==='transcribe' ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Transcribe
                            </button>
                            </div>
                        </div>

                    </div>
                    </div>
                    {
                        transcribe.text !== '' ?
                        <div className="personal-details-box">
                           <div className="row">
                                <div className="col-md-10 mx-auto mt-5">
                                    <div className="form-box">
                                    <h4 className="text-white mb-3">Transcribe Completed</h4>
                                    <div className="form-group">
                                    <textarea value={transcribe.text} id="transcribeInput" className="form-control">{transcribe.text}</textarea>
                                    </div>
                                    <button type="button" className="btn gradiant-button-pb border-15 mt-2" onClick={()=>downloadTxtFile('transcribe','transcribeInput')}>Download as TXT</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }

                    {/* Start Translate */}
                    {
                        translate.text !== '' ?
                            <>
                                <div className="personal-details-box">
                                    <div className="row">
                                        <div className="col-md-10 mx-auto mt-5">
                                        <div className="form-box">   
                                        <h4 className="text-white mb-3">Translate</h4>
                                            <div className="">
                                                <div className="form-group forSelect">
                                                <select className="form-control" name="lang_code" onChange={(e)=>handleTranslateChange(e)}>
                                                    <option selected disabled>-- Select Languages--</option>
                                                    {
                                                        translateLang.length > 0 ?
                                                            translateLang.map((item,index)=>(
                                                                <option key={index} value={item.code}>{item.name}</option>
                                                            ))
                                                            : null
                                                    }
                                                </select>
                                                </div>
                                            </div>
                                            <button type="button" className="btn gradiant-button-pb border-15 btn-submit"
                                                    onClick={()=>translateText()} disabled={loader.status}
                                            >
                                                { loader.status && loader.type==='translate' ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Translate
                                            </button>
                                        </div>
                                        </div> 
                                    </div>
                                </div>

                                {/* Textarea for Translated text */}
                                <div className="personal-details-box">
                                 <div className="row">  
                                <div className="col-md-10 mx-auto mt-5">
                                    <div className="form-box">
                                    <h4 className="text-white mb-3">Translate Completed</h4>
                                    <div className="form-group">
                                    <textarea value={translate.text} id="translateInput" className="form-control" rows={5}>{translate.text}</textarea>
                                    </div>
                                    <button type="button" className="btn gradiant-button-pb border-15 mt-2" onClick={()=>downloadTxtFile('translate','translateInput')}>Download as TXT</button>
                                    </div>
                                </div>
                                </div> 
                                </div>
                            </>
                        : null
                    }

                </div>
            </section>

            <Footer/>
        </>
    )
}

export default React.memo(Transcribe)