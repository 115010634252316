const initialState = {
    facebook : [],
    youtube: [],
    twitch: [],
    customRtmp: []
}

export default function (state = initialState, action){
    switch (action.type) {
        case 'ADD_SOCIAL_ACCOUNTS':
            return{
                ...state,
                facebook: action.payload.data.facebook,
                youtube: action.payload.data.youtube,
                twitch: action.payload.data.twitch,
                customRtmp: action.payload.data.custom_rtmp
            }

        case 'ADD_SYVID':
            return {
                ...state,
                syvid: action.payload
            }

        case 'REMOVE_SOCIAL_ACCOUNT':
            return {
                ...initialState
            }

        default:
            return state
    }
}