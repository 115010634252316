import React, {useState} from 'react';
import template1 from "../../../images/template-1.png";
import template2 from "../../../images/template-2.png";
import template3 from "../../../images/template-3.png";
import template4 from "../../../images/template-4.png";
import template5 from "../../../images/template-5.png";
import template6 from "../../../images/template-6.png";

const presetTemplates = [template1,template2,template3,template4,template5,template6];
const Presets = ({setPreviewType, previewType}) => {
    return (
        <div className="go-live-box-body-template">
            <ul>
                {presetTemplates.map((template,index) => <li className={previewType === index+1 ? 'active' : ''} onClick={() => setPreviewType(index+1)}>
                    <img src={template} alt="template img"
                         className="img-fluid"/>
                </li>)}
            </ul>
        </div>
    );
};

export default Presets;
