import React from 'react';
import {Nav} from "react-bootstrap";
import broadcastIcon from "../../../images/broadcast-icon.png";
import paletteIcon from "../../../images/palette-icon.png";
import cameraIcon from "../../../images/camera-icon.png";
import musicIcon from "../../../images/music.png";
import commentIcon from "../../../images/comment-icon.png";

const StudioNavigation = () => {
    return (
        <Nav variant="pills" className="">
            <Nav.Item>
                <Nav.Link eventKey="first">
                    <div className="edit-page-box-tab-box">
                        <img src={broadcastIcon} alt="broadcast icon"
                             className="img-fluid"/>
                        <p>Broadcast</p>
                    </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="four">
                    <div className="edit-page-box-tab-box">
                        <img src={paletteIcon} alt="broadcast icon"
                             className="img-fluid"/>
                        <p>Customization</p>
                    </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="third">
                    <div className="edit-page-box-tab-box">
                        <img src={cameraIcon} alt="broadcast icon"
                             className="img-fluid"/>
                        <p>Banner</p>
                    </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="second">
                    <div className="edit-page-box-tab-box">
                        <img src={musicIcon} alt="broadcast icon"
                             className="img-fluid"/>
                        <p>Music</p>
                    </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="seven">
                    <div className="edit-page-box-tab-box">
                        <img src={commentIcon} alt="broadcast icon"
                             className="img-fluid"/>
                        <p>Comments</p>
                    </div>
                </Nav.Link>
            </Nav.Item>
        </Nav>
    );
};

export default StudioNavigation;
