import React from 'react';
import navBg from "../../images/Navbg.png";
import {Link, NavLink} from "react-router-dom";
import logo from "../../images/StreamReel-logo.png";

const GuestNav = () => {
    return (
        <header>
            <nav className={`navbar px-lg-5 navbar-expand-lg navbar-me fixed-me navbar-light`} style={{backgroundImage: `url(${navBg})`}}>
                <div className="container">
                    <Link className="navbar-brand" to="/dashboard">
                        <img src={logo} alt="logo" className="img-fluid comp-logo"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="text-white"><i className="fas fa-bars"></i></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            {/*<li className="nav-item">*/}
                            {/*    <NavLink className="nav-link" to="/help-and-support">Help</NavLink>*/}
                            {/*</li>*/}
                        </ul>

                    </div>
                </div>
            </nav>
        </header>
    );
};

export default GuestNav;
