import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDesktop, faMicrophone, faUserPlus, faVideo} from "@fortawesome/free-solid-svg-icons";

import closeModalx from "../../images/closeModal.svg"
const GuestCameraSettings = ({addFeed, removeFeed}) => {
    const [showModal, setShowModal] = useState(false);
    const openPop = () => {
        setShowModal(true);
    }
    const closePopUp = () => {
        setShowModal(false);
    }

    const screenRecord = async() => {
        const a =  await navigator.mediaDevices.getDisplayMedia({
            audio: true,
            video: { mediaSource: "screen"}
        });

        a.getVideoTracks()[0].addEventListener('ended', () => removeFeed())

        return a ;
    }

    const handleScreenShare = async () => {
        const screenStream = await screenRecord();
        addFeed({
            stream:screenStream,
            hostType:'self',
            feedType:'screen',
            name:'Screen Share'
        })
    }


    return (
        <>
            <div className="col-12">
                <div className="go-live-box-options">
                    <ul>
                        {/*<li>*/}
                        {/*    <button type="button" className="btn btn-light live-options-btn">*/}
                        {/*    <span className="icon">*/}
                        {/*        <FontAwesomeIcon icon={faMicrophone}> </FontAwesomeIcon>*/}
                        {/*    </span>*/}
                        {/*        Mute*/}
                        {/*    </button>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <button type="button" className="btn btn-light live-options-btn">*/}
                        {/*    <span className="icon">*/}
                        {/*        <FontAwesomeIcon icon={faVideo}> </FontAwesomeIcon>*/}
                        {/*    </span>*/}
                        {/*        Stop Cam*/}
                        {/*    </button>*/}
                        {/*</li>*/}
                        <li>
                            <button type="button" className="btn btn-light live-options-btn" onClick={handleScreenShare}>
                            <span className="icon">
                                <FontAwesomeIcon icon={faDesktop}> </FontAwesomeIcon>
                            </span>
                                Share Screen
                            </button>
                        </li>
                    </ul>
                </div>
            </div>


            <div className={`commonModal commonModal-sm modal ${showModal ? 'show-modal' : ''} `}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">

                        {/* <h5 className="modal-title"></h5> */}
                        <button onClick={(e)=>closePopUp('')} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <img src={closeModalx} />
                        </button>

                        <div className="modal-body">
                            <div className="broadcast-content">
                                <div className="box-form-control">
                                    <h2 className="popTitle">Invite</h2>
                                    <div className="form-group">
                                        <label for="inputTitle">Title</label>
                                        <input type="text" className="form-control" id="inputTitle" placeholder="Add Title" value="" />
                                    </div>
                                    <div className="">
                                        <button className="btn btn-block btn-link btn-gradient-orange border-50 popBtn">Copy to Clipboard</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default GuestCameraSettings;
