import React from "react";
import imac from "../../images/IMAC1.png"
import mac from "../../images/MAC1.png"

import imac1 from "../../images/imac-1.png"
import imac2 from "../../images/imac-2.png"
import imac3 from "../../images/imac-3.png"
import imac4 from "../../images/imac-4.png"

const DashboardAbout = () => {
    return(
        <>
            <section className="dashborad-sec-3">
                <div className="container">
                    <div className="row">
                    <div className="col-12 col-md-6">
                            <div className="dashborad-sec-3-img imgsizeleft pl-lg-5 pl-md-5 order-md-last">
                                <img src={imac1} alt="mac" className="img-mac"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center order-md-first">
                            <div className="dashborad-sec-3-content text-white">
                                {/* <h6>Get your next video content in 60 seconds</h6> */}
                                <h4 className="mt-3">Create videos simply by pasting</h4>
                                <p className="mt-4">Schedule and stream weeks’ worth of pre-recorded videos for maximum traffic and engagement. You also get the freedom to message your viewers and guests privately or publicly.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="dashborad-sec-3">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="dashborad-sec-3-img imgsizeright">
                                <img src={imac2} alt="mac" className="img-mac"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center">
                            <div className="dashborad-sec-3-content text-white">
                                {/* <h6>Polish your videos effortlessly</h6> */}
                                <h4 className="mt-3">Get Full Control On Your Guest List & Chat Forum</h4>
                                <p className="mt-4">Forget about spammers and hijackers. Easily invite, ban, or even co-host with a guest by sharing your screen. You can also manually authenticate each guest entrance for maximum protection.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="dashborad-sec-3">
                <div className="container">
                    <div className="row">
                    <div className="col-12 col-md-6 order-md-last">
                            <div className="dashborad-sec-3-img imgsizeleft pl-lg-5 pl-md-5">
                                <img src={imac3} alt="mac" className="img-mac"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center order-md-first">
                            <div className="dashborad-sec-3-content text-white">
                                {/* <h6>Boost your viewership and watch time</h6> */}
                                <h4 className="mt-3">Host Professional-Looking Meetings & Livecasts With 100% Customization</h4>
                                <p className="mt-4">Whether you want to split your screen time or broadcast solo, maximize your screen time by adding customized banners, avatars, animated graphics, overlays, and much more.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="dashborad-sec-3">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="dashborad-sec-3-img imgsizeright">
                                <img src={imac4} alt="" className="img-mac"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center">
                            <div className="dashborad-sec-3-content text-white">
                                {/* <h6>Create videos that stand out</h6> */}
                                <h4 className="mt-3 pr-lg-5">Simultaneously Livestream Across Any Platform</h4>
                                <p className="mt-4">Broadcast live or pre-recorded videos across Facebook pages, profiles, and groups, LinkedIn, YouTube, Periscope, Twitch, Instagram, or even custom RTP all at the same time.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardAbout;