import axios from "axios";
import { baseURL } from "../global/global";
import { setAlert } from "./alert";

export const addStudioData = (data) => (dispatch) => {
    dispatch({ type: 'ADD_STUDIO_DATA', payload: data });
}

const updateObject = (obj, type, value) => {
    switch (type) {
        case 'host_name':
            return {
                ...obj,
                host_name: value
            }

        case 'thumbnail':
            return {
                ...obj,
                thumbnail: value
            }

        case 'social_platform':
            return {
                ...obj,
                social_platform: value
            }

        case 'schedule':
            return {
                ...obj,
                schedule: value
            }

        case 'banner':
            return {
                ...obj,
                banner: value
            }

        case 'music':
            return {
                ...obj,
                music: value !== '' ? [value] : []
            }

        case 'music_volume':
            obj.music[0].volume = value;
            return {
                ...obj
            }

        case 'music_loop':
            obj.music[0].loop = value;
            return {
                ...obj
            }
        case 'room_id':
            obj.roomId = value;
            return {
                ...obj
            }

        default:
            return obj
    }
}


export const updateStudioData = (type, value, setLoader = false) => (dispatch, getState) => {
    let tempObj = updateObject(getState().studio, type, value);
    axios({
        method: 'POST',
        url: `${baseURL}update-broadcast`,
        data: tempObj,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch(addStudioData(res.data.data));
        } else {
            dispatch(setAlert(res.data.message, 'danger'));
        }

        if (type === 'banner') setLoader(false);

    }).catch(err => {
        if (type === 'banner') setLoader(false);
    })
}


export const updateCustomizeRedux = (data) => (dispatch) => {

    dispatch({ type: 'UPDATE_CUSTOMIZE_DATA', payload: data });
}


export const updateCustomizeLayers = (type, data) => (dispatch) => {
    dispatch({ type: type, payload: data });
}