import React, {useRef, useEffect} from 'react';

const BackgroundCanvas = ({customization, setCustomization}) => {
    const backgroundRef = useRef(null);
    const backgroundCanvasRef  = useRef(null);
    const background  = customization.background;

    useEffect(() => {
        if(background.enable === true){
            setTimeout(() => {
                const canvas = backgroundCanvasRef.current;
                const context = canvas.getContext("2d");
                context.drawImage(backgroundRef.current, 0, 0, 1280, 720)
                const backgroundCanvasStream = canvas.captureStream(30);
                const newBackground = {...background,stream:backgroundCanvasStream}
                setCustomization({...customization, background: newBackground});
            },3000)
        }
    }, [background.enable,background.src]);


    return (
        <>
            <div className='backgroundCanvas bb' style={{display:'none'}}>
                <canvas ref={backgroundCanvasRef} width="1280" height="720" />
            </div>
            {background.enable === true ? <img ref={backgroundRef} crossOrigin="anonymous" className="img-left-absolute bgAbs" src={background.src}/> : null}
        </>

    );
};

export default BackgroundCanvas;
