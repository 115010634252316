import React, {useEffect, useState} from 'react';
import user1 from "../../../images/user-women.jpg";
import facebookIcon from "../../../images/facebook-round.png";
import youtubeIcon from "../../../images/youtube-round.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {Tab, Dropdown} from "react-bootstrap";
import {useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../../../global/global";
import CommentSocial from "./CommentSocial";

let closeInterval = false;
const Comments = () => {

    const auth = useSelector(state => state.auth);
    const broadcastId = useSelector(state => state.studio.id);
    const broadcastSocial = useSelector(state => state.studio.social_platform);
    const socialData = useSelector(state => state.social);

    const [statusMessage, setStatusMessage] = useState({
        status: 0,
        message: ''
    });
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState('')
    const [socialId, setSocialId] = useState([]);
    const [comments, setComments] = useState([]);
    const [messageImage, setMessageImage] = useState(auth.user?.image)

    const handlePostComments = (e) => {
        e.preventDefault();
        setLoader(true);

        axios({
            method: 'POST',
            url: `${baseURL}add-comments`,
            data: {id: broadcastId, social: socialId, message: message},
            headers: {'Content-Type': 'application/json','Authorization': auth.token }
        }).then(res=>{
            if(res.data.status === true){
                fetchComments();

                setStatusMessage({status: 1, message: res.data.message});
            }else{
                setStatusMessage({status: 2, message: res.data.message});
            }

            setTimeout(()=>{
                setStatusMessage({status: 0, message: ''});
            },3000)

            setLoader(false);
            setMessage('');
        }).catch(err=>{
            setLoader(false);
            setMessage('');
        })
    }

    const fetchComments = () => {
        axios({
            method: 'POST',
            url: `${baseURL}fetch-comments`,
            data: {broadcast_id: broadcastId},
            headers: {'Content-Type': 'application/json','Authorization': auth.token }
        }).then(res=>{
            if(res.data.status === true){
                setComments(res.data.data);
            }
        }).catch(err=>{

        })
    }

    const handleClick = (id, image) => {
        if(id) {
            setSocialId([id]);
            setMessageImage(image);
        }else{
            setSocialId(
                broadcastSocial.map(item=>(
                    item.social_id
                ))
            )
            setMessageImage(auth.user?.image)
        }
    }

    useEffect(()=>{
        fetchComments();
    },[])

    useEffect(()=>{
        setSocialId(
            broadcastSocial.map(item=>(
                item.social_id
            ))
        )
    },[broadcastSocial])

    /* fetch comments in every 5sec */
    useEffect(()=>{
        closeInterval = setInterval(()=>{
            fetchComments();
        },5000)

        return () => {
            if(closeInterval !== false){
                clearInterval(closeInterval);
            }
        }
    },[])

    return (
        <Tab.Pane eventKey="seven">

            <h2 className="tabTitle alt">Comments</h2>
            {
                statusMessage.status === 1 ?
                    <p className="text-center"><span className="badge badge-success">{statusMessage.message}</span></p>
                    : ''
            }

            {
                statusMessage.status === 2 ?
                    <p className="text-center"><span className="badge badge-danger">{statusMessage.message}</span></p>
                    : ''
            }
            <div className="content-box--comments purple-box box-form-control text-white mb-4">

                <div className="row">
                    <div className="col-12">
                        <div>
                            <div className="comments-content-box--chat-room">
                                <div className="comments-content-box--chat-conversation">
                                    {/* <div className="comments-content-box--chat-conversation-top d-inline-block d-md-block d-xl-flex justify-content-end mb-4">
                                                                        <button type="button" class="btn btn-hide-chat mr-2">
                                                                            <span className="icon mr-1"><FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon></span>
                                                                            Show on Stream</button>
                                                                         <button type="button" class="btn btn-hide-chat">
                                                                            <span className="icon mr-1"><FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon></span>
                                                                            Hide Chat</button>
                                                                    </div> */}
                                    <div className="comments-content-box--chat-conversation-body">

                                        {
                                            comments && comments.map((item,index)=>(
                                                <div className="comments-content-box--chat-conversation-body-box  d-sm-flex d-md-block d-xl-flex  align-items-center mb-2 show">
                                                    <div className="comments-content-box-circle-img group mr-3">
                                                        <img src={item?.profile} alt="user img" className="img-fluid"/>
                                                        <div className="proficon"><img src={item.type === 'facebook' ? facebookIcon : youtubeIcon} alt="user img"/></div>
                                                    </div>
                                                    <p>{item?.displayMessage}</p>

                                                    <div className="commentHd">
                                                        <span><FontAwesomeIcon icon={faEyeSlash}></FontAwesomeIcon></span>
                                                        <span>Hide</span>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>

                                <form method="post" onSubmit={(e)=>handlePostComments(e)} className="comments-content-box--chat-submit">
                                    <div className="comments-content-box--chat-submit-post d-flex align-items-center">
                                        <div className="comments-content-box-circle-img mr-3">
                                            <img src={messageImage} alt="user img" className="img-fluid"/>
                                        </div>
                                        <div className='commentInp'>  
                                        <input onChange={(e)=>setMessage(e.target.value)} value={message} type="text" id="chattext" placeholder="Post a comment" className="form-control purple"/>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <button disabled={loader} type="submit" className="btn btn-primary btn-gradient-orange btn-xs-small">
                                            <span className=""> { loader ? <i className="fa fa-spinner fa-spin"/>  : <FontAwesomeIcon icon={faPaperPlane}> </FontAwesomeIcon> } </span>
                                        </button>
                                    </div>
                                </form>

                                <CommentSocial auth={auth} socialData={socialData} handleClick={handleClick} />

                                <div className="alert alert-info">
                                    Comments will be posted to / fetched from <b>Facebook</b> only when the Broadcast is live.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Tab.Pane>
    );
};

export default Comments;
