import React from "react";
import {Dropdown} from "react-bootstrap";

import fbLogo from "../../../images/logo-facebook.svg";
import ytLogo from "../../../images/youtube-round.png";
import fbLogo2 from "../../../images/facebook-round.png";
import twitchLogo from "../../../images/TWITCH-Default.png";

const CommentSocial = ({auth, socialData, handleClick}) => {

    return(
        <>
            <div className='socialDrop'>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic"><i className="fa fa-angle-down" aria-hidden="true"></i></Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={()=>handleClick(false)}>
                            <div className="descImg">
                              <img src={auth.user?.image} alt="user img" className="userImg"/>
                            </div>
                            <div className='desctxt'>
                                <p>All</p>
                            </div>
                        </Dropdown.Item>

                    {
                        socialData.facebook &&
                            socialData.facebook.map(item=>(
                                <Dropdown.Item onClick={()=>handleClick(item.id, item?.image)} key={item.id}>
                                    <div className="descImg">
                                      <img src={item?.image} alt="user img" className="userImg"/>
                                      <div className='socialIcon'>
                                        <img src={fbLogo2} />
                                      </div>
                                    </div>
                                    <div className='desctxt'>
                                        <p>{item?.username}</p>
                                    </div>
                                </Dropdown.Item>
                            ))
                    }
                    {
                        socialData.youtube &&
                            socialData.youtube.map(item=>(
                                <Dropdown.Item onClick={()=>handleClick(item.id, item?.image)} key={item.id}>
                                    <div className="descImg">
                                       <img src={item.image} alt="user img" className="userImg"/>
                                       <div className='socialIcon'>
                                        <img src={ytLogo} />
                                        </div>
                                    </div>
                                    <div className='desctxt'>
                                        <p>{item?.username}</p>
                                    </div>
                                </Dropdown.Item>
                            ))
                    }
                        {
                            socialData.twitch &&
                                socialData.twitch.map(item=>(
                                    <Dropdown.Item onClick={()=>handleClick(item.id, twitchLogo)} key={item.id}>
                                        <div className="descImg">
                                            <img src={twitchLogo} alt="user img" className="userImg"/>
                                            <div className='socialIcon'>
                                                <img src={twitchLogo} />
                                            </div>
                                        </div>
                                        <div className='desctxt'>
                                            <p>{item?.username}</p>
                                        </div>
                                    </Dropdown.Item>
                                ))
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default React.memo(CommentSocial);