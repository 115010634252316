import React, {useEffect, useRef} from 'react';
import {useSelector} from "react-redux";

const BannerCanvas = ({customization, setCustomization}) => {
    const bannerCanvasRef  = useRef(null);

    const studioData = useSelector(state => state.studio);
    const banner = studioData.banner.find(text => text.enable === true)

    const  banner_default = (heading,color) => {
        const ctx = bannerCanvasRef.current.getContext("2d");
        const fontsize = 17;
        const canvasHeight = 30;
        const canvasWidth = 670;

        let globalx = 20;
        const vector = -1;

        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        ctx.fillStyle = color;
        ctx.fillRect (0, 0, canvasWidth, canvasHeight);

        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect (10, 0, canvasWidth, canvasHeight);

        ctx.fillStyle = '#000000'
        ctx.font = fontsize + 'px Helvetica';
        ctx.textBaseline = 'top';
        if (globalx < 0 - ctx.measureText(heading).width) {
            globalx = canvasWidth;
        }
        ctx.fillText(heading, globalx, (canvasHeight-fontsize)/2);

        globalx += vector;

    }


    const  banner_news = (heading,color) => {
        const ctx = bannerCanvasRef.current.getContext("2d");
        const fontsize = 17;
        const canvasHeight = 30;
        const canvasWidth = 670;

        let globalx = 10;
        const vector = -1;

        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        ctx.fillStyle = color;
        ctx.fillRect (0, 0, canvasWidth, canvasHeight);

        ctx.fillStyle = '#ffffff'
        ctx.font = fontsize + 'px Helvetica';
        ctx.textBaseline = 'top';
        if (globalx < 0 - ctx.measureText(heading).width) {
            globalx = canvasWidth;
        }
        ctx.fillText(heading, globalx, (canvasHeight-fontsize)/2);

        globalx += vector;

    }


    const  banner_rounded = (heading,color) => {
        const ctx = bannerCanvasRef.current.getContext("2d");
        const fontsize = 17;
        const canvasHeight = 30;
        const canvasWidth = 670;

        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        let globalx = 10;
        const vector = -1;

         const t = (ctx, x, y, width, height, radius) => {
            if (width < 2 * radius) radius = width / 2;
            if (height < 2 * radius) radius = height / 2;
             ctx.beginPath();
             ctx.moveTo(x + radius, y);
             ctx.arcTo(x + width, y, x + width, y + height, radius);
             ctx.arcTo(x + width, y + height, x, y + height, radius);
             ctx.arcTo(x, y + height, x, y, radius);
             ctx.arcTo(x, y, x + width, y, radius);
             ctx.closePath();
            return ctx;
        }

        t(ctx,0,0,670,30,10)
        ctx.fillStyle = color;
        ctx.fill();


        ctx.fillStyle = '#ffffff'
        ctx.font = fontsize + 'px Helvetica';
        ctx.textBaseline = 'top';
        if (globalx < 0 - ctx.measureText(heading).width) {
            globalx = canvasWidth;
        }
        ctx.fillText(heading, globalx, (canvasHeight-fontsize)/2);

        globalx += vector;

    }



    useEffect(() => {
        // banner_rounded('This is the Heading This is the Heading This is the Heading This is the Heading ')
        const style = studioData.customization.text_style;
        const color = studioData.customization.color;
        if(banner !== undefined && banner.enable){
            if(style === 'default'){
                banner_default(banner.head,color)
            }

            if(style === 'news'){
                banner_news(banner.head,color)
            }

            if(style === 'rounded'){
                banner_rounded(banner.head,color)
            }

            const canvas = bannerCanvasRef.current;
            const bannerCanvasStream = canvas.captureStream();
            const bannerData = {
                enable:true,
                src:'',
                stream:bannerCanvasStream
            }
            setCustomization({...customization, banner: bannerData});

        }
        else{
            const bannerData = {
                enable:false,
                src:'',
                stream:false
            }
            setCustomization({...customization, banner: bannerData});
        }


    }, [studioData.customization.color,studioData.customization.text_style,banner]);

    return (
        <>
            {banner !== undefined && banner.enable === true ? <canvas ref={bannerCanvasRef} width="670" height="30"/> : null}
        </>


    );
};

export default BannerCanvas;
