import React, {useState} from "react";
import {useSelector} from "react-redux";
import queryString from "query-string";
import {Link} from "react-router-dom";

const TrainingTitles = ({location, id, name}) => {

    const supportData = useSelector(state => state.support);
    const articleId = queryString.parse(location.search).id;

    const [articles, setArticles] = useState(supportData.articles);
    const currentArt = supportData.articles.filter((article)=> article.id === id);

    console.log(id);
    console.log(supportData);
    console.log(currentArt);

    return(
        <>
        <div className="container">
        <div className="row">
            <div className="col-12">
            <div className="mb-2 support-data">
                    <Link to="/help-and-support" className="support-link">Support </Link>
                    /
                    <Link className={`${name ? '' : 'active-help'} support-link`} to={`training?id=${currentArt[0].id}`}> {currentArt[0].name} </Link>
                    {
                        name ? <> / <span className="active-help">{name.title}</span></> : ''
                    }

                </div>
            </div>
        </div>
        </div>
            <div className="col-md-4">
                
                <div className="popular-topics-box">
                    <h5 className="text-white">POPULAR TOPICS</h5>

                    <div className="nav flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {
                            articles.length > 0 ?
                                articles.map((item,index)=>{
                                    return(
                                        <Link key={index} className={`nav-link ${articleId === item.id ? 'active' : '' }`} to={`/training?id=${item.id}`} >
                                            {item.name}
                                        </Link>
                                    )
                                })
                            : ''
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default TrainingTitles;